import React from "react";
import { Bar, Nav } from "./styled";
import { Link } from 'found';
import ListGroup from 'react-bootstrap/ListGroup';
import BottomContent from './bottomContent'

let languageMap = {
  es: "spanish",
  fr: "french",
  it: "italian"
}


class BottomBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentMode: false,
    }
    this.toggleComment = this.toggleComment.bind(this);
  };

  toggleComment() {
    this.setState(state => ({commentMode: !state.commentMode}));
  }

  createLinks() {
    let links = []
    if (this.props.snippet) {
      links.push(
        {
          url: `https://www.linguee.com/${languageMap[this.props.language]}-english/translation/${this.props.snippet}.html`,
          text: 'Translation',
          isAnchor: true,
        }
      )
      links.push(
        {
          url: `https://forvo.com/word/${this.props.snippet}/#${this.props.language}`,
          text: 'Pronunciation',
          isAnchor: true,
        }
      )
    }

    if (this.props.wordData && this.props.wordData.pos == "VERB") {
      links.push(
        {
          url: `http://conjugator.reverso.net/conjugation-${languageMap[this.props.language]}-verb-${this.props.wordData.lemma}.html`,
          text: 'Conjugation',
          isAnchor: true,
        }
      )
    }

    if (this.props.chapterTitle && this.props.bookTitle != "elementary_es" && this.props.bookTitle != "le_comte_de_monte_cristo") {
      links.push(
        {
          url: `/flashcards/${this.props.bookTitle}/${this.props.chapterTitle}/verbs`,
          text: 'Chapter Vocab',
          isAnchor: false,
        }
      )
    }
    links.push(
      {
        url: `/flashcards/${this.props.bookTitle}/Entire Text/verbs`,
        text: 'Book Vocab',
        isAnchor: false,
      }
    )
    return links;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.highlightedContent != this.props.highlightedContent){
      this.setState(state => (
      {
          commentMode: nextProps.commentMode,
        }
      ));
    }
  }
  linkHtml(link) {
    if (link.isAnchor) {
      return (
        <ListGroup.Item action href={link.url} target="_blank" style={{fontSize: "16px"}}>
          {link.text}
        </ListGroup.Item>
      );
    } else {
      return (
        <ListGroup.Item action href={link.url} style={{fontSize: "16px"}}>
          {link.text}
        </ListGroup.Item>
      );
    }
  }

  render() {
    return (
      <Bar>
        <ListGroup style={{flex: 1, borderRight: "3px solid black"}} variant="flush">
            {this.createLinks().map((link, index) => {
              return (
                this.linkHtml(link)
              );
            })}

        </ListGroup>
        <BottomContent
          rendition={this.props.rendition}
          highlightedContent={this.props.highlightedContent}
          epubcfi={this.props.epubcfi}
          commentMode={this.state.commentMode}
          toggleComment={this.toggleComment}
          commentText={this.props.commentText}
          mode={this.props.mode}
          wordData={this.props.wordData}
          toggleVerb={this.props.toggleVerb}
          toggleNoun={this.props.toggleNoun}
          toggleAdjective={this.props.toggleAdjective}
          highlightVerbs={this.props.highlightVerbs}
          highlightNouns={this.props.highlightNouns}
          highlightAdjectives={this.props.highlightAdjectives}
      />
      </Bar>
    )
  }
}

export default BottomBar;
