// Generated by purs version 0.13.3
"use strict";
var $foreign = require("./foreign.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var toPrecision = function (digits) {
    return function (n) {
        return $foreign["_toPrecision"](Data_Function["const"](Data_Maybe.Nothing.value), Data_Maybe.Just.create, digits, n);
    };
};
var toFixed = function (digits) {
    return function (n) {
        return $foreign["_toFixed"](Data_Function["const"](Data_Maybe.Nothing.value), Data_Maybe.Just.create, digits, n);
    };
};
var toExponential = function (digits) {
    return function (n) {
        return $foreign["_toExponential"](Data_Function["const"](Data_Maybe.Nothing.value), Data_Maybe.Just.create, digits, n);
    };
};
var $$encodeURIComponent = function (s) {
    return $foreign["_encodeURIComponent"](Data_Function["const"](Data_Maybe.Nothing.value), Data_Maybe.Just.create, s);
};
var $$encodeURI = function (s) {
    return $foreign["_encodeURI"](Data_Function["const"](Data_Maybe.Nothing.value), Data_Maybe.Just.create, s);
};
var $$decodeURIComponent = function (s) {
    return $foreign["_decodeURIComponent"](Data_Function["const"](Data_Maybe.Nothing.value), Data_Maybe.Just.create, s);
};
var $$decodeURI = function (s) {
    return $foreign["_decodeURI"](Data_Function["const"](Data_Maybe.Nothing.value), Data_Maybe.Just.create, s);
};
module.exports = {
    toFixed: toFixed,
    toExponential: toExponential,
    toPrecision: toPrecision,
    "decodeURI": $$decodeURI,
    "encodeURI": $$encodeURI,
    "decodeURIComponent": $$decodeURIComponent,
    "encodeURIComponent": $$encodeURIComponent,
    nan: $foreign.nan,
    "isNaN": $foreign["isNaN"],
    infinity: $foreign.infinity,
    "isFinite": $foreign["isFinite"],
    readInt: $foreign.readInt,
    readFloat: $foreign.readFloat
};
