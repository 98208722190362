import { Container } from "./styled";
import BookView from "./bookView";
import { Route, makeRouteConfig, createFarceRouter, Redirect, createRender, resolver } from "found";
import { reactComponent as BookIndex} from "~/output/BookIndex";
import SignupWrapper from "./signupWrapper";
import Login from "./login";
import Policy from "./policy";
import Support from "./support";
import Layout from "./layout";
import FlashcardPage from "./flashcardPage";
import { StripeProvider } from 'react-stripe-elements';
import { BrowserProtocol, queryMiddleware } from 'farce';
import { reactComponent as Account} from "~/output/Account";
import { reactComponent as Export} from "~/output/Export";
import React from 'react';
import { gql } from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { createHttpLink } from "apollo-link-http";
import client from "../apolloClient"
import { isAuthed } from "./isAuthed"


/*
  .StripeElement {
    margin-top: 20px;
    margin-bottom: 5px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 10px;
  }
  */

const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],
  routeConfig: makeRouteConfig(
    
    <Route
      path="/"
      Component={Layout}
   >
    <Route
        path="/privacy-policy"
        Component={Policy}
      />

      <Route
        path="/login"
        Component={isAuthed(Login, false)}
     />
     <Route
        path="/support"
        Component={Support}
      />
      <Route
        path="/signup"
        Component={isAuthed(SignupWrapper, false)}
     />
      <Route
        path="/account"
        Component={isAuthed(Account, true)}
      />
      <Route
        path="/export"
        Component={isAuthed(Export, true)}
      />

      <Route
        path="/books"
        Component={BookIndex}
          />
      <Route
        path="/books/:book"
        Component={isAuthed(BookView, true)}
          />
      <Route path="flashcards/:book/:chapterTitle/:pos">
        <Route
          Component={isAuthed(FlashcardPage, true)}
        />
      </Route>
      <Redirect from="/" to="/books" />
    </Route>
 ),
  renderError: ({ error }) => (
    <div>{error.status === 404 ? 'Not found' : 'Error'}</div>
  )
});

function AuthenticatedApp() {
  return (
    <Container>
      <ApolloProvider client={client}>
        <StripeProvider apiKey={process.env.STRIPE_KEY}>
          <Router resolver={resolver}/>
        </StripeProvider>
      </ApolloProvider>
    </Container>
  );
}


export default AuthenticatedApp;
