// Generated by purs version 0.13.3
"use strict";
var Data_Maybe = require("../Data.Maybe/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var initialState = {};
var divContent = function (v) {
    if (v instanceof Data_Maybe.Nothing) {
        return React_Basic_DOM_Generated.p_([ React_Basic_DOM.text("\x0a            Highlight words to get parts of speech, tenses, and translations.\x0a            There are links for getting crowdsourced translations and pronunciations on the left upon highlighting\x0a            as well as flashcards of the most frequent words in the chapter and book.\x0a") ]);
    };
    if (v instanceof Data_Maybe.Just) {
        return React_Basic_DOM.text("Translation: " + v.value0);
    };
    throw new Error("Failed pattern match at WordTranslation (line 13, column 1 - line 13, column 34): " + [ v.constructor.name ]);
};
var render = function (self) {
    return React_Basic_DOM_Generated.div_([ divContent(self.props.highlightedContent) ]);
};
var component = React_Basic.createComponent("WordTranslation");
var wordTranslation = React_Basic.make()(component)({
    initialState: initialState,
    render: render
});
module.exports = {
    divContent: divContent,
    wordTranslation: wordTranslation,
    initialState: initialState,
    component: component,
    render: render
};
