// Generated by purs version 0.13.3
"use strict";
var $foreign = require("./foreign.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var unsafeReadProtoTagged = function (name) {
    return function (value) {
        return $foreign["_unsafeReadProtoTagged"](Data_Maybe.Nothing.value, Data_Maybe.Just.create, name, value);
    };
};
module.exports = {
    unsafeReadProtoTagged: unsafeReadProtoTagged
};
