// Generated by purs version 0.13.3
"use strict";
var $foreign = require("./foreign.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var item = function (i) {
    var $0 = $foreign["_item"](i);
    return function ($1) {
        return Data_Nullable.toMaybe($0($1));
    };
};
module.exports = {
    item: item,
    length: $foreign.length
};
