import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const storage = global.localStorage || null;
const mutation = gql`
mutation loginMutation($input: LoginInput!) {
  login(input: $input) {
    session {token}
  }
}
`;

function Login({router}) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [mutate, { mutationData }] = useMutation(mutation);
  const onSubmit = (event) => {
    event.preventDefault();

    mutate({variables: {input: {email, password}}}).then(function(result){
      let session = `Bearer ${result.data.login.session.token}`
      storage && storage.setItem("treader-session", session);
      router.push('/books');
    });
  }

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control onChange={e => setEmail(e.target.value)} type="email" placeholder="Enter email" />
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control onChange={e => setPassword(e.target.value)} type="password" placeholder="Password" />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  )
}

export default Login;
