// Generated by purs version 0.13.3
"use strict";
var ApolloHooks = require("../ApolloHooks/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var MaterialUI = require("../MaterialUI/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var Web_Storage_Storage = require("../Web.Storage.Storage/index.js");
var mutation = ApolloHooks.gql("\x0amutation unsubscribeMutation {\x0a  unsubscribe {\x0a    result\x0a  }\x0a}\x0a");
var convertProps = function (jsProps) {
    return {
        push: Effect_Uncurried.runEffectFn1(jsProps.router.push)
    };
};
var content = React_Basic.element(MaterialUI.dialogContent)({
    children: [ React_Basic.element(MaterialUI.dialogContentText)({
        children: [ React_Basic_DOM.text("We're sorry to see you go!") ]
    }) ],
    style: React_Basic_DOM.css({
        width: "600px"
    })
});
var actions = function (setOpen) {
    return function (mutate) {
        return function (props) {
            return React_Basic.element(MaterialUI.dialogActions)({
                children: [ React_Basic.element(MaterialUI.button)({
                    children: [ React_Basic_DOM.text("confirm") ],
                    color: "secondary",
                    onClick: React_Basic_DOM_Events.capture_(function __do() {
                        var v = Web_HTML.window();
                        var v1 = Web_HTML_Window.localStorage(v)();
                        return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(mutate({}))(function (v2) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Web_Storage_Storage.removeItem("treader-session")(v1)))(function () {
                                return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(props.push("/books"));
                            });
                        }))();
                    })
                }), React_Basic.element(MaterialUI.button)({
                    children: [ React_Basic_DOM.text("cancel") ],
                    color: "primary",
                    onClick: React_Basic_DOM_Events.capture_(setOpen(function (v) {
                        return false;
                    }))
                }) ]
            });
        };
    };
};
var modal = function (isOpen) {
    return function (setOpen) {
        return function (props) {
            return function (mutate) {
                return React_Basic.element(MaterialUI.dialog)({
                    children: [ React_Basic.element(MaterialUI.dialogTitle)({
                        children: [ React_Basic_DOM.text("Unsubscribe") ],
                        style: React_Basic_DOM.css({
                            fontWeight: "600",
                            fontSize: ".875rem"
                        })
                    }), content, actions(setOpen)(mutate)(props) ],
                    onClose: React_Basic_DOM_Events.capture_(setOpen(function (v) {
                        return false;
                    })),
                    open: isOpen,
                    ariaLabelledby: "unsubscribe-form"
                });
            };
        };
    };
};
var buildJsx = function (props) {
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(ApolloHooks.useMutation(mutation)({}))(function (v) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v1) {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div_([ modal(v1.value0)(v1.value1)(convertProps(props))(v.value0), React_Basic.element(MaterialUI.paper)({
                children: [ React_Basic_DOM_Generated.button()({
                    children: [ React_Basic_DOM.text("unsubscribe") ],
                    onClick: React_Basic_DOM_Events.capture_(v1.value1(function (v2) {
                        return true;
                    }))
                }) ]
            }) ]));
        });
    });
};
var reactComponent = Effect_Unsafe.unsafePerformEffect(React_Basic_Hooks.component()()("Account")(buildJsx));
module.exports = {
    mutation: mutation,
    convertProps: convertProps,
    reactComponent: reactComponent,
    buildJsx: buildJsx,
    modal: modal,
    content: content,
    actions: actions
};
