// Generated by purs version 0.13.3
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var MaterialUI = require("../MaterialUI/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var formWrapperJsx = function (children) {
    return React_Basic_DOM_Generated.div()({
        children: children,
        style: React_Basic_DOM.css({
            padding: "40px"
        })
    });
};
var outerJsx = function (children) {
    return React_Basic.element(MaterialUI.paper)({
        children: [ formWrapperJsx(children) ],
        style: React_Basic_DOM.css({
            maxWidth: "800px",
            margin: "0 auto auto",
            backgroundColor: "#fff",
            boxShadow: "0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)"
        })
    });
};
var reactComponent = Effect_Unsafe.unsafePerformEffect(React_Basic_Hooks.component()()("FormCard")(function (props) {
    return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(outerJsx(props.children));
}));
module.exports = {
    reactComponent: reactComponent,
    formWrapperJsx: formWrapperJsx,
    outerJsx: outerJsx
};
