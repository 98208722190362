export default {
  container: {
    overflow: "hidden",
    width: "100%",
    display: "flex"
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  readerArea: {
    display: "flex",
    zIndex: 1,
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#fff",
    transition: "all .3s ease",
    marginLeft: "-300px",
  },
  containerExpaned: {
    transform: "translateX(256px)",
  },
  topBar: {
    paddingLeft: "10px",
    borderBottom: "3px solid black",
    backgroundColor: "#20232a",
    flex: 1,
    height: "50px",
    display: "flex",
    paddingTop: 5,
    color: "white",
    justifyContent: "center",
    alignContent: "center",
  },
  titleArea: {
    flex: 35,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
  },
  reader: {
    flex: 19,
    height: "100%"
  },
  swipeWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 200
  },
  prev: {
    left: 1
  },
  next: {
    right: 1
  },
  arrow: {
    outline: "none",
    border: "none",
    background: "none",
    position: "absolute",
    top: "40%",
    marginTop: -32,
    fontSize: 64,
    padding: "0 10px",
    color: "#E2E2E2",
    fontFamily: "arial, sans-serif",
    cursor: "pointer",
    userSelect: "none",
    appearance: "none",
    fontWeight: "normal"
  },
  arrowHover: {
    color: "#777"
  },
  tocBackground: {
    zIndex: 1
  },
  tocContainer: {
    flex: "1 0 300px",
    //transform: "translateX(-100%)",
  },
  tocArea: {
    width: 256,
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    background: "#f2f2f2",
    padding: "10px 0"
  },
  tocAreaButton: {
    userSelect: "none",
    appearance: "none",
    background: "none",
    border: "none",
    display: "block",
    fontFamily: "sans-serif",
    width: "100%",
    fontSize: ".9em",
    textAlign: "left",
    padding: ".9em 1em",
    borderBottom: "1px solid #ddd",
    color: "#aaa",
    outline: "none",
    cursor: "pointer"
  },
  tocButton: {
    height: "30px",
    width: "30px",
    background: "none",
    border: "none",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 2,
    outline: "none"
  },
  tocButtonExpaned: {
    background: "#f2f2f2",
  },
  tocButtonBar: {
    width: "20px",
    background: "#ccc",
    height: 2,
    transition: "all .5s ease",
  },
  tocButtonBarTop: {
  },
  tocButtonBottom: {
    marginTop: "8px"
  },
  loadingView: {
  }
};
