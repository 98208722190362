var dialog = require('@material-ui/core/Dialog');
var dialogActions = require('@material-ui/core/DialogActions');
var dialogContent = require('@material-ui/core/DialogContent');
var dialogContentText = require('@material-ui/core/DialogContentText');
var dialogTitle = require('@material-ui/core/DialogTitle');
var button = require('@material-ui/core/Button');
var paper = require('@material-ui/core/Paper')
var addIcon = require('@material-ui/icons/Add');
var styles = require('@material-ui/core/styles');
var textField = require('@material-ui/core/TextField')

exports.dialog = dialog.default
exports.dialogActions = dialogActions.default
exports.dialogContent = dialogContent.default
exports.dialogContentText = dialogContentText.default
exports.dialogTitle = dialogTitle.default
exports.button = button.default
exports.paper = paper.default
exports.addIcon = addIcon.default
exports.textField = textField.default
exports._makeStyles = styles.makeStyles
