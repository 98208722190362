import { gql } from 'apollo-boost';
import React from 'react';
import { useQuery } from "react-apollo";
import { ApolloProvider } from '@apollo/react-hooks';
import client from "~/src/javascript/client/apolloClient"

const GET_USER_BOOK = gql`
query getUserBook($book: String) {
  currentUser(book: $book) {
    firstName
    lastName
    email
    isGuest
  }
}`

const GET_USER = gql`
query getUser {
  currentUser {
    id
    firstName
    lastName
    email
    isGuest
  }
}`;

function redirect(error, data, router, shouldBeAuthed) {
  const authed = error != "Error: GraphQL error: Not Authorized"
  if (!shouldBeAuthed && authed && data && data.currentUser && !data.currentUser.isGuest) {
    router.push('/books');
  } else if (shouldBeAuthed && !authed && !(data && data.currentUser  && data.currentUser.isGuest)) {
    router.push('/login');
  }
}

export function isAuthed(Route, shouldBeAuthed) {
  return (props) => {
    const { loading, error, data } = useQuery(
      GET_USER_BOOK,
      {
        client: client,
        variables: { book: props.match.params.book }
      }
    )
    if (loading) {
      return(<div>Loading</div>)
    }
    else {
      redirect(error, data, props.router, shouldBeAuthed);
      return <Route loading={loading} error={error} data={data} {...props}/>
    }
  };
};

