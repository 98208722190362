import React from "react";
import Immutable from 'immutable'
import { Link } from 'found';
import _ from 'lodash'
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from "react-apollo";
//import "../styles/flashcard.scss";

let background = "#F1F1F1";

let headerColor = "#666666";
let wrapperStyle = {
  width: "100%",
  height: "100vh"
}

let contentWrapperStyle =  {
  display: "flex",
  height: "93vh",
  justifyContent: "center",
  alignItems: "center",
  background: background,
  margin: "0 auto",
  padding: "0 2em"
}

let cardStyle = {
  width: "30em",
  height: "15em",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "white",
  boxShadow: "2px 3px 23px rgba(0,0,0,.1)",
  padding: "1em 3em",
	transformStyle: "preserve-3d",
  marginTop: "4em",
  transition: "0.2s",
  color: "black"
}


let flipCardStyle =  {
  position: "absolute",
  top: "4%",
  right: "5%",
  cursor: "pointer",
  color: "#333"
}

let counterStyle =  {
  position: "absolute",
  left: "5%",
  top: "5%"
}

let cardFrontContentStyle = (c) => {
  if(c == "front") {
    return {
      animation: "fadeInUp 0.5s",
      fontWeight: "bold"
    }
  }else if(c == "back") {
    return {
      animation: "fadeIn 0.6s",
      lineHeight: "1.5",
      fontSize: "14px",
    }
  }
}

let sidebarStyle = {
  background: "white",
  boxShadow: "7px 7px 22px lightgray",
  padding: "5em 0em",
  paddingBottom: "10em",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: headerColor,
  //position: "absolute",
  width: "100%",
  flex: 1,
  background: "white",
  zIndex: "0"
}
let headerStyle = {
  background: "white",
  boxShadow: "7px 7px 22px lightgray",
  padding: "1em 0em",
  display: "flex",
  alignItems: "center",
  height: "3vh",
  color: headerColor,
  position: "absolute",
  width: "100%",
  zIndex: "1"
}

let headerContentStyle = (placement) => {
  let result = {
    width: "33.33%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "400"
  }
  if(placement == "right") {
    result["justifyContent"] = "flex-end";
    result["zIndex"] = "11";
  } else if(placement == "left") {
    result["justifyContent"] = "flex-start";
  }
  return result;
}

let createCardStyle = {
  bottom: "0",
  left: "0",
  position: "fixed",
  right: "0",
  top: "0",
  zIndex: "10",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}

let createCardShadowStyle = {
  bottom: "0",
  left: "0",
  position: "fixed",
  right: "0",
  top: "0",
  background: "rgba(0,0,0,0.5)",
  zIndex: "101",
  height: "100%",
  width: "100%",
  transition: "0.5s",
}

let createCardBody = {
  position: "absolute",
  height: "20em",
  width: "30em",
  zIndex: "1000",
  background: "rgba(255,255,255,0.95)",
  animation: "fadeIn 0.5s",
  textAlign: "center",
  boxShadow: "inset 0px 0px 0px 3px gray",
  outline: "4px solid white",
  left: "50%",
  top: "50%",
  transform: "translateX(-50%)translateY(-50%)",
}

let bodyHeaderStyle = {
  fontSize: "1.3em",
  marginTop: "2.5em",
  color: "#333",
}

let createCardPlaceholder = {
  transition: "0.2s",
}

let createCardInputWrapper = {
  textAlign: "center",
}

let createCardInput = {
  margin: "0.75em 0",
  padding: "0.5em 1em",
  width: "70%",
}

let createCardButton =  {
  border: "1px solid",
  background: "white",
  color: "#333",
  cursor: "pointer",
  marginTop: "1.75em",
  padding: "0.5em 0.75em",
 /*
  &:focus {
    outline: none;
  }
  */
}

let createCardError = {
  color: "tomato",
  marginTop: "1em",
}

let cardContainerIcon = {
  position: "fixed",
  zIndex: "100",
  fontSize: "1.5em",
  top: "0.5em",
  left: "94%",
  transform: "translateX(-50%)",
  cursor: "pointer",
  color: "#333",
}

let cardActions = (activeStatus) => {
  let result = {
    position: "absolute",
    bottom: "10%",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    opacity: "0",
    transition: "0.3s",
    left: "0",
  }
  if (activeStatus == "active"){
    result["opacity"] = 1;
  }
  return result;
}

let cardNextButton = {
  marginLeft: "0.5em",
  cursor: "pointer",
  padding: "0.5em 0.75em",
  background: background,
  color: "#444",
  transition: "0.2s",

    /*
  &:hover {
    background: darken($background, 10%);
  }
  */
}

let cardPrevButton = {
  marginLeft: "0.5em",
  cursor: "pointer",
  padding: "0.5em 0.75em",
  background: background,
  color: "#444",
  transition: "0.2s",

    /*
  &:hover {
    background: darken($background, 10%);
  }
  */
}

let cardContainerDot = (activeState) => {
  let result = {
    margin: "0 1em",
    color: "lightgray",
    cursor: "pointer",
  }
  if (activeState = "active") {
    result["color"] = "dodgerblue";
    result["transition"] = "0.5s";
  }
}

let cardContainerDotsWrapper = {
  padding: "1em 0em",
  marginTop: "1em",
  textAlign: "center",
}
class CreateCard extends React.Component {
  constructor() {
    super();
    this.state = {
      word: '',
      description: '',
      showError: false
    }
  }

  hideError() {
    this.setState({showError: !this.state.showError});
  }

  render() {
    const errorMessage = this.state.showError ? 'Please fill in the word and description!' : '';
    return (
      <div style={createCardStyle} className='create-card'>
      <div
      style={createCardShadowStyle}
      className='create-card__shadow'
      onClick={() => {
        this.props.onShadowClick();
      }}
      >
      </div>
      <div style={createCardBody} className='create-card__body'>
      <h1 style={bodyHeaderStyle} >Create New Card</h1>
      <div style={createCardInputWrapper} className='create-card__input-wrapper'>
      <input
      style={createCardInput}
      id='word'
      placeholder="Word i.e. 'React'"
      value = {this.state.word}
      onChange = {(e) => this.setState({word: e.target.value})}
      />
      <input
      id='description'
      placeholder="Description i.e. 'A front end js framework.'"
      value = {this.state.description}
      onChange = {(e) => this.setState({description: e.target.value})}
            />
            <br/>
            <button
              id='create-card__button'
              style={createCardButton}
              onClick={() => {

                if (this.state.word.length === 0 || this.state.description.length === 0) {
                  this.setState({showError: !this.state.showError});
                  setTimeout(() => this.hideError(), 2000);
                } else {
                  this.props.onShadowClick();
                  const word = new Immutable.Map({word: this.state.word, description: this.state.description});
                  this.props.onCreateCard(word);
                }
              }}
            >
                Create!
            </button>
            <div className='create-card__error' style={createCardError}>
              {errorMessage}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false
    }
  }

  render() {
    return (
      <div className='header' style={headerStyle}>
        <div style={headerContentStyle("left")} className='header-content header-content__left'>
        </div>
        <div style={headerContentStyle("middle")} className='header-content header-content__middle'>
          Flash Cards
        </div>
        <div style={headerContentStyle("right")} className='header-content header-content__right'>

        </div>
      </div>
    )
  }
}
class Card extends React.Component {

  constructor() {
    super();
    this.state = {
      showAnswer: false
    }
  }

  render() {
    const content = this.state.showAnswer ? this.props.backContent : this.props.frontContent;
    const iconClass = this.state.showAnswer ? 'reply' : 'share';
    const cardClass = this.state.showAnswer ? 'back' : '';
    const contentClass = this.state.showAnswer ? 'back' : 'front';
    const actionClass = this.state.showAnswer ? 'active' : '';

    return (
      <div
        style={cardStyle}
        className={`card ${cardClass}`}
        onClick={() => this.setState({showAnswer: !this.state.showAnswer})}
      >
      <span className='card__counter' style={counterStyle}>{this.props.cardNumber + 1}</span>
        <div
          style={flipCardStyle}
          className='card__flip-card'
          onClick={ () => {
            this.setState({showAnswer: !this.state.showAnswer});
          }}
        >

          <span className={`fa fa-${iconClass}`}/>
        </div>
        <div style={cardFrontContentStyle(contentClass)} className={`card__content--${contentClass}`}>
          {content}
        </div>
        <div className={`card__actions ${actionClass}`} style={cardActions(actionClass)}>
          <div
            className='card__prev-button'
            style={cardPrevButton}
            onClick={() => {
              this.props.showPrevCard();
              this.setState({showAnswer: false});
            }}
          >
            Prev
          </div>
          <div
            className='card__next-button'
            style={cardNextButton}
            onClick={() => {
              this.props.showNextCard();
              this.setState({showAnswer: false});
            }}
          >
            Next
          </div>
        </div>
      </div>
    );
  }
}

class CardContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      cards: Immutable.fromJS([{
        word: 'Jazz',
        description: 'A type of music of black American origin characterized by improvisation, syncopation, and usually a regular or forceful rhythm, emerging at the beginning of the 20th century.',
      }, {
        word: 'Reggae',
        description: 'Music like Bob Marley, man.',
      }, {
        word: 'Folk',
        description: 'Music like Bob Dylan, man.',
      }
    ]),
      cardNumber: 0
    };
    this.boundCallback = this.hideCreateCard.bind(this);
    this.boundShowPrevCard = this.showPrevCard.bind(this);
    this.boundShowNextCard = this.showNextCard.bind(this);
  }

  hideCreateCard() {
    this.setState({showModal: false});
  }

  showNextCard() {
    if ((this.state.cardNumber + 1) !== this.props.cards.size) {
      this.setState({cardNumber: this.state.cardNumber += 1});
    }
  }

  showPrevCard() {
    if (this.state.cardNumber !== 0) {
      this.setState({cardNumber: this.state.cardNumber -= 1});
    }
  }

  generateDots() {
    const times = this.props.cards.size;
    let arr = [];
    _.times(times).forEach((num) => {
      const dotClass = num  === this.state.cardNumber ? 'active' : '';
      arr.push(
        <span
          className={`card-container__dot fa fa-circle ${dotClass}`}
          style={cardContainerDot(dotClass)}
          onClick={() => this.setState({cardNumber: num})}
        />
      )
    });
    return arr;
  }

  generateCards() {
    const cards = this.props.cards;
    const cardsList = cards.map((card) => {
      return (
        <Card
        frontContent={card.get('word')}
        backContent={card.get('translation')}
        showNextCard={this.boundShowNextCard}
        showPrevCard = {this.boundShowPrevCard}
        cardNumber={this.state.cardNumber}
        />
      );
    })
    return(cardsList.toJS()[this.state.cardNumber]);
  }
  render() {
    return (
      <div>
      <span
            className='card-container__icon  fa fa-plus'
            style={cardContainerIcon}
            onClick={() => {
              this.setState({showModal: !this.state.showModal});
            }}
          />
        {this.state.showModal
          ? <CreateCard
              onShadowClick={this.boundCallback}
              onCreateCard={this.boundCreateCard}
            />
          : ''}
        {this.generateCards()}
        <div className='card-container__dots-wrapper' style={cardContainerDotsWrapper}>
          {this.generateDots()}
        </div>
      </div>
   );
  }
}
let query=gql`
  query routes_FlashcardPage_Query($book: String, $chapterTitle: String, $pos: String) {
    flashcards(book: $book, chapterTitle: $chapterTitle, pos: $pos) {
      translation
      word
    }
  }`

function FlashcardPage({match, }) {

  let matchParams = match.params;
  const result = useQuery(query, {variables: {book: matchParams.book, chapterTitle: matchParams.chapterTitle, pos: matchParams.pos }, client: client});
  if (result.loading) {
    return <div>Loading</div>
  } else {
    return (
      <div style={wrapperStyle}>
        <Header book={matchParams.book} chapterTitle={matchParams.chapterTitle} />
        <div style={{display: "flex", height: "93vh"}}>
          <div style={sidebarStyle}>

            <Link style={{flex: 1}} to={`/flashcards/${matchParams.book}/${matchParams.chapterTitle}/verbs`}>Verbs</Link>
            <Link style={{flex: 1}} to={`/flashcards/${matchParams.book}/${matchParams.chapterTitle}/nouns`}>Nouns</Link>
            <Link style={{flex: 1}} to={`/flashcards/${matchParams.book}/${matchParams.chapterTitle}/adj`}>Adjectives</Link>
          </div>
          <div style={{flex: 11}}>
            <div style={contentWrapperStyle}>
              <CardContainer cards={Immutable.fromJS(result.data.flashcards)} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default FlashcardPage;
