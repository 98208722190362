import React from "react";
import Reader from "./reader";
import { ReaderContainer } from "./styled";
import { useQuery, useMutation } from "react-apollo";
import client from "../apolloClient"
import BottomBar from './bottomBar'
import GlobalStyles from "./globalStyles"
import { colors } from "../constants"
import Header from "./header";
import { gql } from 'apollo-boost';
import { isBrowser, isMobile } from "react-device-detect";
import renditionHandler from "../services/renditionHandler";

const storage = global.localStorage || null;
export const modes = ['read', 'viewComment', 'clickKnown']

class BookView extends React.Component {
  constructor(props) {
    super(props);

    let book = this.props.match.params.book
    this.state = {
      modeIndex: storage && storage.getItem("mode-index") ? storage.getItem("mode-index") : 0,
      fullscreen: false,
      commentText: false,
      commentMode: false,
      commentIndex: storage && storage.getItem("comment-index") ? storage.getItem("comment-index") : 0,
      location:
        storage && storage.getItem(`${book}-epub-location`)
          ? storage.getItem(`${book}-epub-location`)
          : (book == "elementary_es" ? "epubcfi(/6/2[item9]!/4/50[EL_CUENTO_DEL_POLLO]/4/8,/1:0,/1:9)" : 0),
      largeText: false,
      highlightVerbs:
        storage && storage.getItem("highlight-verbs")
          ? storage.getItem("highlight-verbs") == "true"
          : true,
      highlightNouns:
        storage && storage.getItem("highlight-nouns")
          ? storage.getItem("highlight-nouns") == "true"
          : true,
      highlightAdjectives:
        storage && storage.getItem("highlight-adjectives")
          ? storage.getItem("highlight-adjectives") == "true"
          : true,
    };
    this.rendition = null;
  };

  changeComment = (newCommentIndex, oldCommentIndex, rendition, modeIndex) => {
    if (newCommentIndex !== oldCommentIndex) {
      let newLocation = this.props.comments[newCommentIndex].cfirange
      this.setState(
        {
          commentIndex: newCommentIndex,
          location: newLocation,
          modeIndex: modeIndex,
        },
        () => {
          rendition.annotations.remove(this.props.comments[oldCommentIndex].cfirange, 'highlight');
          rendition.annotations.highlight(newLocation);
          storage && storage.setItem("comment-index", newCommentIndex);
          storage && storage.setItem("mode-index", modeIndex);
        }
      );
    }
  }

  cycleMode = (rendition) => {
    let index = parseInt(this.state.modeIndex) + 1
    if(index < modes.length) {
      this.setState({modeIndex: index},
        () => {
          storage && storage.setItem("mode-index", index);
        }
      );
    } else {
      this.incrementComment(rendition)
    }
  }

  cycleModeBackwards = (rendition) => {
    let index = parseInt(this.state.modeIndex) - 1
    if(index >= 0) {
      this.setState({modeIndex: index},
        () => {
          storage && storage.setItem("mode-index", index);
        }
      );
    } else {
      this.decrementComment(rendition)
    }
  }

  decrementComment = (rendition) => {
    let index = parseInt(this.state.commentIndex)
    if (index !== 0) {
      this.changeComment(index - 1, index, rendition, modes.length - 1)
      return true;
    }
    return false;
  }

  incrementComment = (rendition) => {
    let index = parseInt(this.state.commentIndex)
    let length = this.props.comments.length - 1
    if (index !== length) {
      this.changeComment(index + 1, index, rendition, 0)
      return true;
    }
    return false;
  }

  onLocationChanged = location => {

    let book = this.props.match.params.book
    this.setState(
      {
        location
      },
      () => {
        this.setTitles();
        storage && storage.setItem(`${book}-epub-location`, location);
      }
    );
  };

  toggleVerb = () => {
    this.setState(state => ({highlightVerbs: !state.highlightVerbs}),
    () => {
      storage && storage.setItem("highlight-verbs", this.state.highlightVerbs);
      this.rendition.themes.register("new", this.setTheme());
      this.rendition.themes.select("new");
    });
  }

  toggleNoun = () => {
    this.setState(state => ({highlightNouns: !state.highlightNouns}),
    () => { 
      storage && storage.setItem("highlight-nouns", this.state.highlightNouns);
      this.rendition.themes.register("new", this.setTheme());
      this.rendition.themes.select("new");
    });
  }

  toggleAdjective = () => {
    this.setState(state => ({highlightAdjectives: !state.highlightAdjectives}),
    () => {
      storage && storage.setItem("highlight-adjectives", this.state.highlightAdjectives);
      this.rendition.themes.register("new", this.setTheme());
      this.rendition.themes.select("new");
    });
  }

  setTheme = () => {
    let themes = {};

    if (this.state.highlightVerbs) {
      themes['[data-pos="VERB"]'] = {
        'color': colors["green"]
      }
      themes['[data-pos="AUX"]'] = {
        'color': colors["green"]
      }
    } else {
      themes['[data-pos="VERB"]'] = {
        'color': "black"
      }
      themes['[data-pos="AUX"]'] = {
        'color': "black"
      }
    }
    if (this.state.highlightNouns) {
      themes['[data-pos="NOUN"]'] = {
        'color': colors["orange"]
      }
      themes['[data-pos="PROPN"]'] = {
        'color': colors["orange"]
      }
    } else {
      themes['[data-pos="NOUN"]'] = {
        'color': "black"
      }
      themes['[data-pos="PROPN"]'] = {
        'color': "black"
      }
    }
    if (this.state.highlightAdjectives) {
      themes['[data-pos="ADJ"]'] = {
        'color': colors["red"]
      }
    } else {
      themes['[data-pos="ADJ"]'] = {
        'color': "black"
      }
    }
    return themes;
  }

  setTitles = () => {
    let book = this.rendition.book
    let locationCfi = this.state.location
    let spineItem = book.spine.get(locationCfi);
    let bookTitle = book.package.metadata.title
    let language = book.package.metadata.language.toLowerCase();
    let chapterTitle = ""
    if (bookTitle == "Italian Riveduta 1927 (RIV)") {
      let chapterNumber = spineItem.href.slice(-7)[0]
      let titleHref = spineItem.href.slice(0, -7) + "0.xhtml"
      let secondDigit = spineItem.href.slice(-8)[0]
      if (secondDigit != ".") {
        titleHref = spineItem.href.slice(0, -8) + "0.xhtml"
        chapterNumber = secondDigit + chapterNumber;
      }
      let navItem = book.navigation.get(titleHref);
      chapterTitle = navItem.label.trim() + ` ${chapterNumber}`
    } else {
      let navItem = book.navigation.get(spineItem.href);
      if (navItem == null) {
        book.navigation.forEach((nav) => {
          if (nav.href.includes(spineItem.href)) {
            navItem = nav;
          }
        })
      }
      chapterTitle = navItem == null ? "" : navItem.label.trim()
    }
    this.setState(
      {
        chapterTitle: chapterTitle,
        language: language
      }
    );
  } 
  getRendition = (rendition) => {
    this.rendition = rendition;
    renditionHandler(rendition, this, this.props.comments);
  };

  titleBar() {
    return <Header user={this.props.currentUser} router={this.props.router} />
  }

  render() {
    const { fullscreen, location, highlightedContent, snippet, epubcfi, commentIndex, modeIndex, wordData, highlightVerbs, highlightNouns, highlightAdjectives, chapterTitle, language, commentMode } = this.state;
    let text = this.props.comments && this.props.comments[commentIndex] ? this.props.comments[commentIndex].text : ""
    let suffix = this.props.match.params.book
    suffix = this.props.match.params.book == "RIV" || this.props.match.params.book == "la_odisea" || this.props.match.params.book == "elementary_es" || this.props.match.params.book == "le_comte_de_monte_cristo" ? suffix : `private/${suffix}`
    let prefix = ""
    if (process.env.NODE_ENV === "production") {
      prefix = "/dist";
    }
    let url = this.props.book.processedEpubUrl || this.props.book.epubUrl
    return (
      <div style={{height: "100vh", display: "flex", flexDirection: "column"}}>
        <GlobalStyles/>
        <ReaderContainer fullscreen={true}>
          <Reader
            url={url}
            title={"Treader"}
            location={location}
            locationChanged={this.onLocationChanged}
            getRendition={this.getRendition}
            titleBar={this.titleBar()}
            swipeable={true}
          />
        </ReaderContainer>
      { true && <BottomBar
          rendition={this.rendition}
          highlightedContent={highlightedContent}
          snippet={snippet}
          epubcfi={epubcfi}
          commentText={text}
          mode={modes[modeIndex]}
          wordData={wordData}
          toggleVerb={this.toggleVerb}
          toggleNoun={this.toggleNoun}
          toggleAdjective={this.toggleAdjective}
          highlightVerbs={highlightVerbs}
          highlightNouns={highlightNouns}
          highlightAdjectives={highlightAdjectives}
          chapterTitle={chapterTitle}
          bookTitle={this.props.match.params.book}
          language={language}
          commentMode={commentMode}
        /> }
      </div>
    );
  }
}

const query = gql`
  query routes_Book_Query($book: String) {
    currentUser(book: $book) {
      firstName
      lastName
      email
    }
    book(slug: $book) {
      epubUrl
      processedEpubUrl
    }
  }
`
const mutation = gql`
  mutation translateMutation($input: TranslateInput!) {
    translate(input: $input) {
      translation
    }
  }
`;
passApolloProps = (Component) => {
  return (props) => {
    const result = useQuery(query, {variables: {book: props.match.params.book }, client: client})
    const [mutate, { mutationData }]  = useMutation(mutation, {client: client})
    if (result.loading) {
      return <div>Loading</div>
    } else {

      return <Component currentUser={result.data.currentUser} comments={result.data.comments} book={result.data.book} mutationFn={mutate} {...props}/>
    }
  }
}
export default passApolloProps(BookView);
