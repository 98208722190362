// Generated by purs version 0.13.3
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Data_Void = require("../Data.Void/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var Record = require("../Record/index.js");
var Type_Data_RowList = require("../Type.Data.RowList/index.js");
var GEncodeJson = function (gEncodeJson) {
    this.gEncodeJson = gEncodeJson;
};
var EncodeJson = function (encodeJson) {
    this.encodeJson = encodeJson;
};
var gEncodeJsonNil = new GEncodeJson(function (v) {
    return function (v1) {
        return Foreign_Object.empty;
    };
});
var gEncodeJson = function (dict) {
    return dict.gEncodeJson;
};
var encodeVoid = new EncodeJson(Data_Void.absurd);
var encodeRecord = function (dictGEncodeJson) {
    return function (dictRowToList) {
        return new EncodeJson(function (rec) {
            return Data_Argonaut_Core.fromObject(gEncodeJson(dictGEncodeJson)(rec)(Type_Data_RowList.RLProxy.value));
        });
    };
};
var encodeJsonUnit = new EncodeJson(Data_Function["const"](Data_Argonaut_Core.jsonNull));
var encodeJsonJson = new EncodeJson(Control_Category.identity(Control_Category.categoryFn));
var encodeJsonJString = new EncodeJson(Data_Argonaut_Core.fromString);
var encodeJsonJNumber = new EncodeJson(Data_Argonaut_Core.fromNumber);
var encodeJsonJBoolean = new EncodeJson(Data_Argonaut_Core.fromBoolean);
var encodeJsonInt = new EncodeJson(function ($40) {
    return Data_Argonaut_Core.fromNumber(Data_Int.toNumber($40));
});
var encodeJson = function (dict) {
    return dict.encodeJson;
};
var encodeJsonArray = function (dictEncodeJson) {
    return new EncodeJson(function (json) {
        return Data_Argonaut_Core.fromArray(Data_Functor.map(Data_Functor.functorArray)(encodeJson(dictEncodeJson))(json));
    });
};
var encodeJsonChar = new EncodeJson((function () {
    var $41 = encodeJson(encodeJsonJString);
    return function ($42) {
        return $41(Data_String_CodeUnits.singleton($42));
    };
})());
var encodeJsonCodePoint = new EncodeJson((function () {
    var $43 = encodeJson(encodeJsonJString);
    return function ($44) {
        return $43(Data_String_CodePoints.singleton($44));
    };
})());
var encodeJsonEither = function (dictEncodeJson) {
    return function (dictEncodeJson1) {
        return new EncodeJson((function () {
            var obj = function (dictEncodeJson2) {
                return function (tag) {
                    return function (x) {
                        return Data_Argonaut_Core.fromObject(Foreign_Object.fromFoldable(Data_List_Types.foldableList)(new Data_List_Types.Cons(new Data_Tuple.Tuple("tag", Data_Argonaut_Core.fromString(tag)), new Data_List_Types.Cons(new Data_Tuple.Tuple("value", encodeJson(dictEncodeJson2)(x)), Data_List_Types.Nil.value))));
                    };
                };
            };
            return Data_Either.either(obj(dictEncodeJson)("Left"))(obj(dictEncodeJson1)("Right"));
        })());
    };
};
var encodeJsonList = function (dictEncodeJson) {
    return new EncodeJson((function () {
        var $45 = Data_Functor.map(Data_Functor.functorArray)(encodeJson(dictEncodeJson));
        var $46 = Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray);
        return function ($47) {
            return Data_Argonaut_Core.fromArray($45($46($47)));
        };
    })());
};
var encodeJsonMaybe = function (dictEncodeJson) {
    return new EncodeJson(function (v) {
        if (v instanceof Data_Maybe.Nothing) {
            return Data_Argonaut_Core.jsonNull;
        };
        if (v instanceof Data_Maybe.Just) {
            return encodeJson(dictEncodeJson)(v.value0);
        };
        throw new Error("Failed pattern match at Data.Argonaut.Encode.Class (line 29, column 1 - line 31, column 37): " + [ v.constructor.name ]);
    });
};
var encodeJsonNonEmptyArray = function (dictEncodeJson) {
    return new EncodeJson(function (v) {
        return encodeJson(encodeJsonArray(dictEncodeJson))(Data_Array.cons(v.value0)(v.value1));
    });
};
var encodeJsonNonEmptyList = function (dictEncodeJson) {
    return new EncodeJson(function (v) {
        return encodeJson(encodeJsonMaybe(encodeJsonList(dictEncodeJson)))(Data_List.insertAt(0)(v.value0)(v.value1));
    });
};
var encodeJsonTuple = function (dictEncodeJson) {
    return function (dictEncodeJson1) {
        return new EncodeJson(function (v) {
            return encodeJson(encodeJsonArray(encodeJsonJson))([ encodeJson(dictEncodeJson)(v.value0), encodeJson(dictEncodeJson1)(v.value1) ]);
        });
    };
};
var encodeMap = function (dictOrd) {
    return function (dictEncodeJson) {
        return function (dictEncodeJson1) {
            return new EncodeJson((function () {
                var $48 = encodeJson(encodeJsonList(encodeJsonTuple(dictEncodeJson)(dictEncodeJson1)));
                var $49 = Data_Map_Internal.toUnfoldable(Data_List_Types.unfoldableList);
                return function ($50) {
                    return $48($49($50));
                };
            })());
        };
    };
};
var encodeSet = function (dictOrd) {
    return function (dictEncodeJson) {
        return new EncodeJson((function () {
            var $51 = encodeJson(encodeJsonList(dictEncodeJson));
            var $52 = Data_Set.toUnfoldable(Data_List_Types.unfoldableList);
            return function ($53) {
                return $51($52($53));
            };
        })());
    };
};
var gEncodeJsonCons = function (dictEncodeJson) {
    return function (dictGEncodeJson) {
        return function (dictIsSymbol) {
            return function (dictCons) {
                return new GEncodeJson(function (row) {
                    return function (v) {
                        return Foreign_Object.insert(Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value))(encodeJson(dictEncodeJson)(Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(row)))(gEncodeJson(dictGEncodeJson)(row)(Type_Data_RowList.RLProxy.value));
                    };
                });
            };
        };
    };
};
var encodeForeignObject = function (dictEncodeJson) {
    return new EncodeJson((function () {
        var $54 = Data_Functor.map(Foreign_Object.functorObject)(encodeJson(dictEncodeJson));
        return function ($55) {
            return Data_Argonaut_Core.fromObject($54($55));
        };
    })());
};
module.exports = {
    encodeJson: encodeJson,
    gEncodeJson: gEncodeJson,
    EncodeJson: EncodeJson,
    GEncodeJson: GEncodeJson,
    encodeJsonMaybe: encodeJsonMaybe,
    encodeJsonTuple: encodeJsonTuple,
    encodeJsonEither: encodeJsonEither,
    encodeJsonUnit: encodeJsonUnit,
    encodeJsonJBoolean: encodeJsonJBoolean,
    encodeJsonJNumber: encodeJsonJNumber,
    encodeJsonInt: encodeJsonInt,
    encodeJsonJString: encodeJsonJString,
    encodeJsonJson: encodeJsonJson,
    encodeJsonCodePoint: encodeJsonCodePoint,
    encodeJsonNonEmptyArray: encodeJsonNonEmptyArray,
    encodeJsonNonEmptyList: encodeJsonNonEmptyList,
    encodeJsonChar: encodeJsonChar,
    encodeJsonArray: encodeJsonArray,
    encodeJsonList: encodeJsonList,
    encodeForeignObject: encodeForeignObject,
    encodeSet: encodeSet,
    encodeMap: encodeMap,
    encodeVoid: encodeVoid,
    encodeRecord: encodeRecord,
    gEncodeJsonNil: gEncodeJsonNil,
    gEncodeJsonCons: gEncodeJsonCons
};
