import { EpubCFI } from "epubjs/lib/index";
const isFirefox = typeof InstallTrigger !== 'undefined';

const commit = (component, snippet, language) => {
  component.props.mutationFn(
    {
      variables: {
        input: {snippet, language}
      }
    }
  ).then(function(response){
    component.setState({ highlightedContent: response.data.translate.translation });
  })
}

export default function renditionHandler(rendition, component, comments) {
  rendition.themes.override("line-height", "1.5");
  rendition.hooks.content.register(function(contents, view) {
    contents.triggerSelectedEvent = function(selection){
      var range, cfirange;

      if (selection && selection.rangeCount > 0) {
        range = selection.getRangeAt(0);
        if(!range.collapsed) {
          this.previouslySelected = true;
          // cfirange = this.section.cfiFromRange(range);
          cfirange = new EpubCFI(range, this.cfiBase).toString();
          this.emit("selected", cfirange);
          this.emit("selectedRange", range);
        } else if (this.previouslySelected) {
          this.previouslySelected = false;
          this.emit("deselected");
        }
      }
    }

    contents.on("deselected", function() {
        component.setState({
          highlightedContent: null,
          snippet: null,
          epubcfi: null,
          wordData: null,
        });
    });
    component.setTitles();
  });

  rendition.themes.default(component.setTheme());
  let keyListener = function(e){
    /*
    if ((e.keyCode || e.which) == 39) {
      rendition.prev();
    }
    if ((e.keyCode || e.which) == 41) {
      rendition.next();
    }
    */
    if (comments[component.state.commentIndex]) {
      if ((e.keyCode || e.which) == 38) {
        component.cycleModeBackwards(rendition);
        console.log("previous")
      }
      if ((e.keyCode || e.which) == 40) {
        component.cycleMode(rendition);
        console.log("next")
      }
    };
  }
  //rendition.on("keyup", keyListener);
  //document.addEventListener("keyup", keyListener, false);

  if (comments && comments[component.state.commentIndex]) {
    rendition.annotations.highlight(comments[component.state.commentIndex].cfirange);
  }

  rendition.on("selected", function(cfiRange) {
    this.book.getRange(cfiRange).then((range) => {
      let span
      if(isFirefox) {
        let container = range.startContainer;
        if (container.nextElementSibling && container.nextElementSibling.nodeName == "SPAN") {
          span = range.startContainer.nextElementSibling;
        } else {
          span = range.startContainer.parentElement;
        }
      } else {
        span = range.commonAncestorContainer.parentElement;
      }
      let text = range.toString();
      if (span && span.tagName.toLowerCase() == 'span' ) {
        component.setState({
          snippet: text,
          epubcfi: cfiRange,
          wordData: span.dataset,
          commentMode: false,
        });
        commit(component, text, component.state.language)
      } else {
        component.setState({
          snippet: text,
          epubcfi: cfiRange,
          wordData: null,
          commentMode: false,
        });
        commit(component, text, component.state.language)
      }
    });
  });
}
