import React from "react";
import readerStyles from "../styles/reader";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { TwitterShareButton } from 'react-twitter-embed';
import { useApolloClient } from '@apollo/react-hooks';
import { useQuery, useMutation } from "react-apollo";
import { gql } from 'apollo-boost';

const storage = global.localStorage || null;
const paragraphStyle = {
  marginTop: "26px"
}
const inputStyle = {
  border: "none",
  boxShadow: "inset 0 -2px 0 0 rgba(0,0,0,.1)",
  fontSize: "18px",
  marginBottom: "45px"
}
const modalBodyStyle = {
  padding: "70px 30px 30px 30px",
  height: "400px",
  boxShadow: "rgba(0,0,0,.15) 0 2px 20px 0",
  border: "none",
  borderRadius: "4px"
}

const mutation = gql`
mutation waitlistUserMutation($input: WaitlistUserInput!) {
  waitlistUser(input: $input) {
    result
  }
}`;

function Header({user, router, refetchUser}) {

  client = useApolloClient();
  const [mutate, { mutationData }]  = useMutation(mutation, {client: client})
  const [show, setShow] = React.useState(false);
  const [next, setNext] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const closeNext = () => setNext(false);
  const openNext = () => setNext(true);
  const redirectAccount = () => {
    router.push('/account');
  }

  const buttons = () => {
    if (!user || user.firstName == "Guest") {
      return (
        <React.Fragment>
          <Button href="/login" style={{marginRight: "10px", width: "100px", fontSize: "16px", paddingTop: "7px"}} size="lg" variant="outline-light">Login</Button>
          <Button style={{fontSize: "16px"}} onClick={handleShow} size="lg" variant="success">Request Access!</Button>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Button style={{marginRight: "10px", width: "100px", fontSize: "16px", paddingTop: "7px"}} onClick={onLogout} size="lg" variant="outline-light">Logout</Button>
          <Button style={{fontSize: "16px"}} onClick={redirectAccount} size="lg" variant="success">Account</Button>
        </React.Fragment>
      );
    }
  }
  const onSubmit = (event) => {
    event.preventDefault();
    mutate({variables: {input: {name, email}}})
    handleClose();
    openNext();
  }
  const onLogout = (event) => {
    event.preventDefault();
    storage && storage.removeItem("treader-session");
    client.resetStore();
    router.push('/books');
  }
  const tweetText = "Learn a language while reading a book with @treader_io. https://treader.io check out a demo at";

  return (
    <React.Fragment>
      <div style={readerStyles.titleArea}>Treader Demo</div>
      {buttons()}
      <Modal show={show} onHide={handleClose} size="md" centered>
        <Modal.Body style={modalBodyStyle}>
          <Form onSubmit={onSubmit} style={{display: "flex", flexDirection: "column", justifyContent: "space-evenly", height: "100%"}}>
            <Form.Control type="text" style={inputStyle} size="lg" placeholder="FULL NAME" value={name} onChange={e => setName(e.target.value)} />
            <Form.Control type="email" style={inputStyle} size="lg" placeholder="EMAIL" value={email} onChange={e => setEmail(e.target.value)}  />
            <Button variant="primary" style={{width: "180px", height: "70px", fontSize: "16px", marginTop: "10px"}} size="lg" type="submit">
              Request Access
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={next} onHide={closeNext} size="md" centered style={{color: "black"}}>
        <Modal.Body style={modalBodyStyle}>
          <p>Thank you for your interest!</p>
          <p style={paragraphStyle}>If you want to skip the wait the fastest way to get in is to be referred by an existing user.</p>
          <p style={paragraphStyle}>Fraser Mince</p>
          <p>Founder</p>
          <p style={paragraphStyle}>Share to get referred!</p>
          <TwitterShareButton
            style={paragraphStyle}
            url="https://app.treader.io/books/elementary_es"
            options={{ text: tweetText, size: "large" }}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default Header;
