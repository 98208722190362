import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const CheckLabel = styled.label`
  vertical-align: middle;
  margin-left: 5px;
`

export const Nav = styled.nav`
  padding-left: 10px;
  flex: 1;
  border-right: 3px solid black;
`;
export const Bar = styled.footer`
  display: flex;
  flex: 2
  width: 100%;
  background-color: white;
  color: black;
  box-sizing: border-box;
  border: 3px solid black;
  line-height: 1.5;
  display:-webkit-flex
  min-height: 0;
`;
export const ReaderContainer = styled.div`
  font-size: 16px;
  display: flex;
  flex: 6
  transition: all 0.6s ease;
  min-height: 0;
  ${props => !props.fullscreen && "0 0 5px rgba(0,0,0,.3);"};
`;
