// Generated by purs version 0.13.3
"use strict";
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var wbr = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("wbr"));
};
var video = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("video"));
};
var video_ = function (children) {
    return video()({
        children: children
    });
};
var $$var = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("var"));
};
var var_ = function (children) {
    return $$var()({
        children: children
    });
};
var ul = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("ul"));
};
var ul_ = function (children) {
    return ul()({
        children: children
    });
};
var u = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("u"));
};
var u_ = function (children) {
    return u()({
        children: children
    });
};
var track = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("track"));
};
var tr = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("tr"));
};
var tr_ = function (children) {
    return tr()({
        children: children
    });
};
var title = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("title"));
};
var title_ = function (children) {
    return title()({
        children: children
    });
};
var time = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("time"));
};
var time_ = function (children) {
    return time()({
        children: children
    });
};
var thead = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("thead"));
};
var thead_ = function (children) {
    return thead()({
        children: children
    });
};
var th = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("th"));
};
var th_ = function (children) {
    return th()({
        children: children
    });
};
var tfoot = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("tfoot"));
};
var tfoot_ = function (children) {
    return tfoot()({
        children: children
    });
};
var textarea = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("textarea"));
};
var textarea_ = function (children) {
    return textarea()({
        children: children
    });
};
var template = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("template"));
};
var template_ = function (children) {
    return template()({
        children: children
    });
};
var td = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("td"));
};
var td_ = function (children) {
    return td()({
        children: children
    });
};
var tbody = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("tbody"));
};
var tbody_ = function (children) {
    return tbody()({
        children: children
    });
};
var table = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("table"));
};
var table_ = function (children) {
    return table()({
        children: children
    });
};
var svg = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("svg"));
};
var svg_ = function (children) {
    return svg()({
        children: children
    });
};
var sup = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("sup"));
};
var sup_ = function (children) {
    return sup()({
        children: children
    });
};
var summary = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("summary"));
};
var summary_ = function (children) {
    return summary()({
        children: children
    });
};
var sub = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("sub"));
};
var sub_ = function (children) {
    return sub()({
        children: children
    });
};
var style = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("style"));
};
var style_ = function (children) {
    return style()({
        children: children
    });
};
var strong = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("strong"));
};
var strong_ = function (children) {
    return strong()({
        children: children
    });
};
var span = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("span"));
};
var span_ = function (children) {
    return span()({
        children: children
    });
};
var source = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("source"));
};
var small = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("small"));
};
var small_ = function (children) {
    return small()({
        children: children
    });
};
var slot = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("slot"));
};
var slot_ = function (children) {
    return slot()({
        children: children
    });
};
var select = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("select"));
};
var select_ = function (children) {
    return select()({
        children: children
    });
};
var section = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("section"));
};
var section_ = function (children) {
    return section()({
        children: children
    });
};
var script = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("script"));
};
var script_ = function (children) {
    return script()({
        children: children
    });
};
var samp = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("samp"));
};
var samp_ = function (children) {
    return samp()({
        children: children
    });
};
var s = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("s"));
};
var s_ = function (children) {
    return s()({
        children: children
    });
};
var ruby = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("ruby"));
};
var ruby_ = function (children) {
    return ruby()({
        children: children
    });
};
var rtc = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("rtc"));
};
var rtc_ = function (children) {
    return rtc()({
        children: children
    });
};
var rt = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("rt"));
};
var rt_ = function (children) {
    return rt()({
        children: children
    });
};
var rp = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("rp"));
};
var rp_ = function (children) {
    return rp()({
        children: children
    });
};
var rb = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("rb"));
};
var rb_ = function (children) {
    return rb()({
        children: children
    });
};
var q = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("q"));
};
var q_ = function (children) {
    return q()({
        children: children
    });
};
var progress = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("progress"));
};
var progress_ = function (children) {
    return progress()({
        children: children
    });
};
var pre = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("pre"));
};
var pre_ = function (children) {
    return pre()({
        children: children
    });
};
var picture = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("picture"));
};
var picture_ = function (children) {
    return picture()({
        children: children
    });
};
var param = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("param"));
};
var p = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("p"));
};
var p_ = function (children) {
    return p()({
        children: children
    });
};
var output = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("output"));
};
var output_ = function (children) {
    return output()({
        children: children
    });
};
var option = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("option"));
};
var option_ = function (children) {
    return option()({
        children: children
    });
};
var optgroup = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("optgroup"));
};
var optgroup_ = function (children) {
    return optgroup()({
        children: children
    });
};
var ol = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("ol"));
};
var ol_ = function (children) {
    return ol()({
        children: children
    });
};
var object = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("object"));
};
var object_ = function (children) {
    return object()({
        children: children
    });
};
var noscript = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("noscript"));
};
var noscript_ = function (children) {
    return noscript()({
        children: children
    });
};
var nav = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("nav"));
};
var nav_ = function (children) {
    return nav()({
        children: children
    });
};
var meter = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("meter"));
};
var meter_ = function (children) {
    return meter()({
        children: children
    });
};
var meta = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("meta"));
};
var menuitem = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("menuitem"));
};
var menuitem_ = function (children) {
    return menuitem()({
        children: children
    });
};
var menu = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("menu"));
};
var menu_ = function (children) {
    return menu()({
        children: children
    });
};
var math = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("math"));
};
var math_ = function (children) {
    return math()({
        children: children
    });
};
var mark = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("mark"));
};
var mark_ = function (children) {
    return mark()({
        children: children
    });
};
var map = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("map"));
};
var map_ = function (children) {
    return map()({
        children: children
    });
};
var main = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("main"));
};
var main_ = function (children) {
    return main()({
        children: children
    });
};
var link = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("link"));
};
var li = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("li"));
};
var li_ = function (children) {
    return li()({
        children: children
    });
};
var legend = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("legend"));
};
var legend_ = function (children) {
    return legend()({
        children: children
    });
};
var label = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("label"));
};
var label_ = function (children) {
    return label()({
        children: children
    });
};
var keygen = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("keygen"));
};
var keygen_ = function (children) {
    return keygen()({
        children: children
    });
};
var kbd = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("kbd"));
};
var kbd_ = function (children) {
    return kbd()({
        children: children
    });
};
var ins = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("ins"));
};
var ins_ = function (children) {
    return ins()({
        children: children
    });
};
var input = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("input"));
};
var img = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("img"));
};
var iframe = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("iframe"));
};
var iframe_ = function (children) {
    return iframe()({
        children: children
    });
};
var i = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("i"));
};
var i_ = function (children) {
    return i()({
        children: children
    });
};
var html = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("html"));
};
var html_ = function (children) {
    return html()({
        children: children
    });
};
var hr = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("hr"));
};
var hgroup = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("hgroup"));
};
var hgroup_ = function (children) {
    return hgroup()({
        children: children
    });
};
var header = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("header"));
};
var header_ = function (children) {
    return header()({
        children: children
    });
};
var head = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("head"));
};
var head_ = function (children) {
    return head()({
        children: children
    });
};
var h6 = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h6"));
};
var h6_ = function (children) {
    return h6()({
        children: children
    });
};
var h5 = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h5"));
};
var h5_ = function (children) {
    return h5()({
        children: children
    });
};
var h4 = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h4"));
};
var h4_ = function (children) {
    return h4()({
        children: children
    });
};
var h3 = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h3"));
};
var h3_ = function (children) {
    return h3()({
        children: children
    });
};
var h2 = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h2"));
};
var h2_ = function (children) {
    return h2()({
        children: children
    });
};
var h1 = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h1"));
};
var h1_ = function (children) {
    return h1()({
        children: children
    });
};
var form = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("form"));
};
var form_ = function (children) {
    return form()({
        children: children
    });
};
var footer = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("footer"));
};
var footer_ = function (children) {
    return footer()({
        children: children
    });
};
var figure = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("figure"));
};
var figure_ = function (children) {
    return figure()({
        children: children
    });
};
var figcaption = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("figcaption"));
};
var figcaption_ = function (children) {
    return figcaption()({
        children: children
    });
};
var fieldset = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("fieldset"));
};
var fieldset_ = function (children) {
    return fieldset()({
        children: children
    });
};
var embed = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("embed"));
};
var em = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("em"));
};
var em_ = function (children) {
    return em()({
        children: children
    });
};
var dt = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("dt"));
};
var dt_ = function (children) {
    return dt()({
        children: children
    });
};
var dl = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("dl"));
};
var dl_ = function (children) {
    return dl()({
        children: children
    });
};
var div = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("div"));
};
var div_ = function (children) {
    return div()({
        children: children
    });
};
var dialog = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("dialog"));
};
var dialog_ = function (children) {
    return dialog()({
        children: children
    });
};
var dfn = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("dfn"));
};
var dfn_ = function (children) {
    return dfn()({
        children: children
    });
};
var details = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("details"));
};
var details_ = function (children) {
    return details()({
        children: children
    });
};
var del = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("del"));
};
var del_ = function (children) {
    return del()({
        children: children
    });
};
var dd = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("dd"));
};
var dd_ = function (children) {
    return dd()({
        children: children
    });
};
var datalist = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("datalist"));
};
var datalist_ = function (children) {
    return datalist()({
        children: children
    });
};
var data$prime = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("data"));
};
var data_ = function (children) {
    return data$prime()({
        children: children
    });
};
var colgroup = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("colgroup"));
};
var colgroup_ = function (children) {
    return colgroup()({
        children: children
    });
};
var col = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("col"));
};
var code = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("code"));
};
var code_ = function (children) {
    return code()({
        children: children
    });
};
var cite = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("cite"));
};
var cite_ = function (children) {
    return cite()({
        children: children
    });
};
var caption = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("caption"));
};
var caption_ = function (children) {
    return caption()({
        children: children
    });
};
var canvas = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("canvas"));
};
var canvas_ = function (children) {
    return canvas()({
        children: children
    });
};
var button = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("button"));
};
var button_ = function (children) {
    return button()({
        children: children
    });
};
var br = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("br"));
};
var body = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("body"));
};
var body_ = function (children) {
    return body()({
        children: children
    });
};
var blockquote = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("blockquote"));
};
var blockquote_ = function (children) {
    return blockquote()({
        children: children
    });
};
var bdo = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("bdo"));
};
var bdo_ = function (children) {
    return bdo()({
        children: children
    });
};
var bdi = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("bdi"));
};
var bdi_ = function (children) {
    return bdi()({
        children: children
    });
};
var base = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("base"));
};
var b = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("b"));
};
var b_ = function (children) {
    return b()({
        children: children
    });
};
var audio = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("audio"));
};
var audio_ = function (children) {
    return audio()({
        children: children
    });
};
var aside = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("aside"));
};
var aside_ = function (children) {
    return aside()({
        children: children
    });
};
var article = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("article"));
};
var article_ = function (children) {
    return article()({
        children: children
    });
};
var area = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("area"));
};
var address = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("address"));
};
var address_ = function (children) {
    return address()({
        children: children
    });
};
var abbr = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("abbr"));
};
var abbr_ = function (children) {
    return abbr()({
        children: children
    });
};
var a = function (dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("a"));
};
var a_ = function (children) {
    return a()({
        children: children
    });
};
module.exports = {
    a: a,
    a_: a_,
    abbr: abbr,
    abbr_: abbr_,
    address: address,
    address_: address_,
    area: area,
    article: article,
    article_: article_,
    aside: aside,
    aside_: aside_,
    audio: audio,
    audio_: audio_,
    b: b,
    b_: b_,
    base: base,
    bdi: bdi,
    bdi_: bdi_,
    bdo: bdo,
    bdo_: bdo_,
    blockquote: blockquote,
    blockquote_: blockquote_,
    body: body,
    body_: body_,
    br: br,
    button: button,
    button_: button_,
    canvas: canvas,
    canvas_: canvas_,
    caption: caption,
    caption_: caption_,
    cite: cite,
    cite_: cite_,
    code: code,
    code_: code_,
    col: col,
    colgroup: colgroup,
    colgroup_: colgroup_,
    "data'": data$prime,
    data_: data_,
    datalist: datalist,
    datalist_: datalist_,
    dd: dd,
    dd_: dd_,
    del: del,
    del_: del_,
    details: details,
    details_: details_,
    dfn: dfn,
    dfn_: dfn_,
    dialog: dialog,
    dialog_: dialog_,
    div: div,
    div_: div_,
    dl: dl,
    dl_: dl_,
    dt: dt,
    dt_: dt_,
    em: em,
    em_: em_,
    embed: embed,
    fieldset: fieldset,
    fieldset_: fieldset_,
    figcaption: figcaption,
    figcaption_: figcaption_,
    figure: figure,
    figure_: figure_,
    footer: footer,
    footer_: footer_,
    form: form,
    form_: form_,
    h1: h1,
    h1_: h1_,
    h2: h2,
    h2_: h2_,
    h3: h3,
    h3_: h3_,
    h4: h4,
    h4_: h4_,
    h5: h5,
    h5_: h5_,
    h6: h6,
    h6_: h6_,
    head: head,
    head_: head_,
    header: header,
    header_: header_,
    hgroup: hgroup,
    hgroup_: hgroup_,
    hr: hr,
    html: html,
    html_: html_,
    i: i,
    i_: i_,
    iframe: iframe,
    iframe_: iframe_,
    img: img,
    input: input,
    ins: ins,
    ins_: ins_,
    kbd: kbd,
    kbd_: kbd_,
    keygen: keygen,
    keygen_: keygen_,
    label: label,
    label_: label_,
    legend: legend,
    legend_: legend_,
    li: li,
    li_: li_,
    link: link,
    main: main,
    main_: main_,
    map: map,
    map_: map_,
    mark: mark,
    mark_: mark_,
    math: math,
    math_: math_,
    menu: menu,
    menu_: menu_,
    menuitem: menuitem,
    menuitem_: menuitem_,
    meta: meta,
    meter: meter,
    meter_: meter_,
    nav: nav,
    nav_: nav_,
    noscript: noscript,
    noscript_: noscript_,
    object: object,
    object_: object_,
    ol: ol,
    ol_: ol_,
    optgroup: optgroup,
    optgroup_: optgroup_,
    option: option,
    option_: option_,
    output: output,
    output_: output_,
    p: p,
    p_: p_,
    param: param,
    picture: picture,
    picture_: picture_,
    pre: pre,
    pre_: pre_,
    progress: progress,
    progress_: progress_,
    q: q,
    q_: q_,
    rb: rb,
    rb_: rb_,
    rp: rp,
    rp_: rp_,
    rt: rt,
    rt_: rt_,
    rtc: rtc,
    rtc_: rtc_,
    ruby: ruby,
    ruby_: ruby_,
    s: s,
    s_: s_,
    samp: samp,
    samp_: samp_,
    script: script,
    script_: script_,
    section: section,
    section_: section_,
    select: select,
    select_: select_,
    slot: slot,
    slot_: slot_,
    small: small,
    small_: small_,
    source: source,
    span: span,
    span_: span_,
    strong: strong,
    strong_: strong_,
    style: style,
    style_: style_,
    sub: sub,
    sub_: sub_,
    summary: summary,
    summary_: summary_,
    sup: sup,
    sup_: sup_,
    svg: svg,
    svg_: svg_,
    table: table,
    table_: table_,
    tbody: tbody,
    tbody_: tbody_,
    td: td,
    td_: td_,
    template: template,
    template_: template_,
    textarea: textarea,
    textarea_: textarea_,
    tfoot: tfoot,
    tfoot_: tfoot_,
    th: th,
    th_: th_,
    thead: thead,
    thead_: thead_,
    time: time,
    time_: time_,
    title: title,
    title_: title_,
    tr: tr,
    tr_: tr_,
    track: track,
    u: u,
    u_: u_,
    ul: ul,
    ul_: ul_,
    "var": $$var,
    var_: var_,
    video: video,
    video_: video_,
    wbr: wbr
};
