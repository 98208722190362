import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Swipeable } from "react-swipeable";
import { EpubView } from "react-reader"
import defaultStyles from "../styles/reader.js";
import { isBrowser, isMobile } from "react-device-detect";

class TocItem extends PureComponent {
  setLocation = () => {
    this.props.setLocation(this.props.href);
  };
  render() {
    const { label, styles } = this.props;
    return (
      <button onClick={this.setLocation} style={styles}>
        {label}
      </button>
    );
  }
}

TocItem.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string,
  setLocation: PropTypes.func,
  styles: PropTypes.object
};

class Reader extends PureComponent {
  constructor(props) {
    super(props);
    this.readerRef = React.createRef();
    this.state = {
      expanedToc: false,
      toc: false
    };
  }
  toggleToc = () => {
    this.setState({
      expanedToc: !this.state.expanedToc
    });
  };

  next = () => {
    const node = this.readerRef.current;
    node.nextPage();
  };

  prev = () => {
    const node = this.readerRef.current;
    node.prevPage();
  };

  onTocChange = toc => {
    const { tocChanged } = this.props;
    this.setState(
      {
        toc: toc
      },
      () => tocChanged && tocChanged(toc)
    );
  };

  renderToc() {
    const { toc, expanedToc } = this.state;
    const { styles } = this.props;
    return (
      <div style={styles.tocContainer}>
        <div style={styles.tocArea}>
          <div style={styles.toc}>
            {toc.map((item, i) => (
              <TocItem
                {...item}
                key={i}
                setLocation={this.setLocation}
                styles={styles.tocAreaButton}
              />
            ))}
          </div>
        </div>
        {expanedToc && (
          <div style={styles.tocBackground} onClick={this.toggleToc} />
        )}
      </div>
    );
  }

  setLocation = loc => {
    const { locationChanged } = this.props;
    this.setState(
      {
        expanedToc: false
      },
      () => locationChanged && locationChanged(loc)
    );
  };

  renderButtons(styles) {
    return(
      <>
        <button
          style={Object.assign({}, styles.arrow, styles.prev)}
          onClick={this.prev}
        >
          ‹
        </button>
        <button
          style={Object.assign({}, styles.arrow, styles.next)}
          onClick={this.next}
        >
          ›
        </button>
      </>
    );
  }

  renderTocToggle() {
    const { expanedToc } = this.state;
    const { styles } = this.props;
    return (
      <div style={styles.buttonWrapper}>
        <button
          style={Object.assign(
            {},
            styles.tocButton,
            expanedToc ? styles.tocButtonExpaned : {}
          )}
          onClick={this.toggleToc}
        >
          <span
            style={Object.assign({}, styles.tocButtonBar, styles.tocButtonBarTop)}
          />
          <span
            style={Object.assign({}, styles.tocButtonBar, styles.tocButtonBottom)}
          />
        </button>
      </div>
    );
  }

  render() {
    const {
      url,
      title,
      showToc,
      loadingView,
      epubOptions,
      styles,
      getRendition,
      locationChanged,
      location,
      titleBar,
      swipeable
    } = this.props;
    const { toc, expanedToc } = this.state;
    return (
      <div style={styles.container}>
        {showToc && toc && this.renderToc()}
        <div
          style={Object.assign(
            {},
            styles.readerArea,
            expanedToc ? styles.containerExpaned : {}
          )}
        >
          <div style={styles.topBar}>
            {showToc && this.renderTocToggle()}
            {titleBar}
          </div>
            <div style={styles.reader}>
              <EpubView
                ref={this.readerRef}
                url={url}
                location={location}
                loadingView={loadingView}
                tocChanged={this.onTocChange}
                locationChanged={locationChanged}
                epubOptions={epubOptions}
                getRendition={getRendition}
              />
            </div>
            {isBrowser && this.renderButtons(styles)}
       </div>
      </div>
    );
  }
}

Reader.defaultProps = {
  loadingView: <div style={defaultStyles.loadingView}>Loading…</div>,
  locationChanged: null,
  tocChanged: null,
  showToc: true,
  styles: defaultStyles
};

Reader.propTypes = {
  title: PropTypes.string,
  loadingView: PropTypes.element,
  url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(ArrayBuffer)
  ]),
  showToc: PropTypes.bool,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locationChanged: PropTypes.func,
  tocChanged: PropTypes.func,
  styles: PropTypes.object,
  epubOptions: PropTypes.object,
  getRendition: PropTypes.func,
  swipeable: PropTypes.bool
};

export default Reader;
