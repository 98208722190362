import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    margin: 0;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-weight: 300;
    line-height: 1.4;
    word-break: break-word;
  }
  html {
    font-size: 62.5%;
  }
  html body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-size: 1.8rem;
    background-color: #f2f5f6;
    position: absolute;
    height: 100vh;
    width: 100vw;
  }
  .modal-dialog {
    max-width: 600px;
  }
  .StripeElement {
    flex: 1;
    border-style: none;
    padding-bottom: 0px;
    outline: none;
    height: 25px;
    box-sizing: content-box;
  }
`;
export default GlobalStyles;
