// Generated by purs version 0.13.3
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Promise = require("../Control.Promise/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Tuple_Native = require("../Data.Tuple.Native/index.js");
var Data_Typelevel_Num_Reps = require("../Data.Typelevel.Num.Reps/index.js");
var Data_Typelevel_Num_Sets = require("../Data.Typelevel.Num.Sets/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Loading = (function () {
    function Loading() {

    };
    Loading.value = new Loading();
    return Loading;
})();
var $$Error = (function () {
    function $$Error(value0) {
        this.value0 = value0;
    };
    $$Error.create = function (value0) {
        return new $$Error(value0);
    };
    return $$Error;
})();
var Data = (function () {
    function Data(value0) {
        this.value0 = value0;
    };
    Data.create = function (value0) {
        return new Data(value0);
    };
    return Data;
})();
var ErrorM = (function () {
    function ErrorM(value0) {
        this.value0 = value0;
    };
    ErrorM.create = function (value0) {
        return new ErrorM(value0);
    };
    return ErrorM;
})();
var DataM = (function () {
    function DataM(value0) {
        this.value0 = value0;
    };
    DataM.create = function (value0) {
        return new DataM(value0);
    };
    return DataM;
})();
var useApolloClient = React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)($foreign["_useApolloClient"])(function (v) {
    return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(Data_Nullable.toMaybe(v));
});
var handleUpdate = function (updateFn) {
    var cache = function (jsCache) {
        var $21 = {};
        for (var $22 in jsCache) {
            if ({}.hasOwnProperty.call(jsCache, $22)) {
                $21[$22] = jsCache[$22];
            };
        };
        $21.readQuery = Effect_Uncurried.runEffectFn1($foreign.runThisFn1("readQuery")(jsCache));
        $21.writeQuery = Effect_Uncurried.runEffectFn1($foreign.runThisFn1("writeQuery")(jsCache));
        return $21;
    };
    return function (jsCache, d) {
        return updateFn(cache(jsCache))(d)();
    };
};
var gql = function (string) {
    return $foreign["_gql"](string);
};
var findStateM = function (v) {
    return function (v1) {
        if (v1 instanceof Data_Maybe.Just) {
            return new DataM(v1.value0);
        };
        if (v instanceof Data_Maybe.Just) {
            return new ErrorM(v.value0);
        };
        return new ErrorM({
            message: "Something went wrong"
        });
    };
};
var mutationState = function (v) {
    var e = Data_Nullable.toMaybe(v.error);
    var d = Data_Nullable.toMaybe(v.data);
    return findStateM(e)(d);
};
var findState = function (v) {
    return function (v1) {
        return function (v2) {
            if (v2 instanceof Data_Maybe.Just) {
                return new Data(v2.value0);
            };
            if (v1 instanceof Data_Maybe.Just) {
                return new $$Error(v1.value0);
            };
            if (v instanceof Data_Maybe.Just) {
                return Loading.value;
            };
            return new $$Error({
                message: "Something went wrong"
            });
        };
    };
};
var queryState = function (v) {
    var l = Data_Nullable.toMaybe(v.loading);
    var e = Data_Nullable.toMaybe(v.error);
    var d = Data_Nullable.toMaybe(v.data);
    return {
        state: findState(l)(e)(d),
        networkStatus: v.networkStatus,
        refetch: Effect_Uncurried.runEffectFn1(v.refetch)
    };
};
var useMutation = function (mutation) {
    return function (options) {
        var mapAff = function (f) {
            return function (x) {
                return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(f(x)))(Control_Promise.toAff);
            };
        };
        var affFn = function (mutationFunction) {
            return function (x) {
                return Control_Bind.bind(Effect_Aff.bindAff)(mapAff(Effect_Uncurried.runEffectFn1(mutationFunction))(x))(function (v) {
                    var v1 = mutationState(v);
                    if (v1 instanceof DataM) {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value0);
                    };
                    if (v1 instanceof ErrorM) {
                        return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error(v1.value0.message));
                    };
                    throw new Error("Failed pattern match at ApolloHooks (line 127, column 6 - line 129, column 51): " + [ v1.constructor.name ]);
                });
            };
        };
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Internal.unsafeHook(function () {
            return $foreign["_useMutation"](mutation, options);
        }))(function (v) {
            var mutationFunction = Data_Tuple_Native.prj()()()()()(Data_Typelevel_Num_Sets.natD0)(Data_Typelevel_Num_Reps.d0)(v);
            var d = Data_Tuple_Native.prj()()()()()(Data_Typelevel_Num_Sets.natD1)(Data_Typelevel_Num_Reps.d1)(v);
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(new Data_Tuple.Tuple(affFn(mutationFunction), queryState(d)));
        });
    };
};
var useQuery = function (query) {
    return function (options) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Internal.unsafeHook(function () {
            return $foreign["_useQuery"](query, options);
        }))(function (v) {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(queryState(v));
        });
    };
};
var eqQueryState = function (dictEq) {
    return new Data_Eq.Eq(function (x) {
        return function (y) {
            if (x instanceof Loading && y instanceof Loading) {
                return true;
            };
            if (x instanceof $$Error && y instanceof $$Error) {
                return x.value0.message === y.value0.message;
            };
            if (x instanceof Data && y instanceof Data) {
                return Data_Eq.eq(dictEq)(x.value0)(y.value0);
            };
            return false;
        };
    });
};
module.exports = {
    Loading: Loading,
    "Error": $$Error,
    Data: Data,
    gql: gql,
    useMutation: useMutation,
    useQuery: useQuery,
    useApolloClient: useApolloClient,
    ErrorM: ErrorM,
    DataM: DataM,
    handleUpdate: handleUpdate,
    eqQueryState: eqQueryState
};
