// Generated by purs version 0.13.3
"use strict";
var ApolloHooks = require("../ApolloHooks/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var MaterialUI = require("../MaterialUI/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Slug = require("../Slug/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Web_File_File = require("../Web.File.File/index.js");
var Web_File_FileList = require("../Web.File.FileList/index.js");
var Web_HTML_HTMLInputElement = require("../Web.HTML.HTMLInputElement/index.js");
var query = ApolloHooks.gql("\x0aquery getUser {\x0a  currentUser {\x0a    id\x0a    firstName\x0a    lastName\x0a    email\x0a    isGuest\x0a    books {\x0a      id\x0a      name\x0a      slug\x0a    }\x0a  }\x0a}\x0a");
var updateCache = function (cache) {
    return function (v) {
        return function __do() {
            var v1 = cache.readQuery({
                query: query
            })();
            var updatedBooks = Data_Array.snoc(v1.currentUser.books)(v.data.book.book);
            var updatedUser = {
                books: updatedBooks,
                "__typename": v1["currentUser"]["__typename"],
                email: v1.currentUser.email,
                firstName: v1.currentUser.firstName,
                id: v1.currentUser.id,
                isGuest: v1.currentUser.isGuest,
                lastName: v1.currentUser.lastName
            };
            return cache.writeQuery({
                data: {
                    currentUser: updatedUser
                },
                query: query
            })();
        };
    };
};
var mutation = ApolloHooks.gql("\x0amutation bookMutation($input: BookInput!) {\x0a  book(input: $input) {\x0a    book {id slug name}\x0a  }\x0a}\x0a");
var liElement = function (href) {
    return function (text) {
        return React_Basic_DOM_Generated.li_([ React_Basic_DOM_Generated.a()({
            href: "/books/" + href,
            children: [ React_Basic_DOM.text(text) ]
        }) ]);
    };
};
var inputRef = (function () {
    var suffixStripped = function (name) {
        return Data_Maybe.fromMaybe(name)(Data_String_CodeUnits.stripSuffix(".epub")(name));
    };
    var slug = function (name) {
        return Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Slug.toString)(Slug.generate(name)));
    };
    var suffixStrippedSlug = function (name) {
        return slug(suffixStripped(name));
    };
    var handleFile = function (setIsSubmitted) {
        return function (mutate) {
            return function (file) {
                return function __do() {
                    setIsSubmitted(function (v) {
                        return true;
                    })();
                    return Effect_Aff.launchAff_(mutate({
                        variables: {
                            input: {
                                file: file,
                                slug: suffixStrippedSlug(Web_File_File.name(file)),
                                name: suffixStripped(Web_File_File.name(file))
                            }
                        }
                    }))();
                };
            };
        };
    };
    var getFile = function (files) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(files)(function (v) {
            return Web_File_FileList.item(0)(v);
        });
    };
    var handleRef = function (setIsSubmitted) {
        return function (mutate) {
            return function (element) {
                return function __do() {
                    var v = Web_HTML_HTMLInputElement.files(element)();
                    return Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(handleFile(setIsSubmitted)(mutate))(getFile(v))();
                };
            };
        };
    };
    var fileText = function (files) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(getFile(files))(function (v) {
            return Data_Maybe.Just.create(Web_File_File.name(v));
        });
    };
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useRef(Data_Nullable["null"]))(function (v) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState("Choose File"))(function (v1) {
            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v2) {
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(ApolloHooks.useMutation(mutation)({
                    update: ApolloHooks.handleUpdate(updateCache),
                    returnPartialData: true
                }))(function (v3) {
                    return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))({
                        value: v1.value0,
                        ref: v,
                        isSubmitted: v2.value0,
                        setIsSubmitted: v2.value1,
                        setButtonDefault: v1.value1(function (v4) {
                            return "Choose File";
                        }),
                        onSubmit: React_Basic_Events.handler(React_Basic_DOM_Events.preventDefault)(function (v4) {
                            return function __do() {
                                var v5 = Data_Functor.map(Effect.functorEffect)(function (v5) {
                                    return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLInputElement.fromNode)(v5);
                                })(React_Basic_Hooks.readRefMaybe(v))();
                                return Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(handleRef(v2.value1)(v3.value0))(v5)();
                            };
                        }),
                        onChange: React_Basic_DOM_Events.capture_(function __do() {
                            var v4 = Data_Functor.map(Effect.functorEffect)(function (v4) {
                                return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLInputElement.fromNode)(v4);
                            })(React_Basic_Hooks.readRefMaybe(v))();
                            if (v4 instanceof Data_Maybe.Nothing) {
                                return v1.value1(function (v5) {
                                    return "Choose File";
                                })();
                            };
                            if (v4 instanceof Data_Maybe.Just) {
                                var v5 = Web_HTML_HTMLInputElement.files(v4.value0)();
                                var newText = fileText(v5);
                                return v1.value1(function (v6) {
                                    return Data_Maybe.fromMaybe("Choose File")(newText);
                                })();
                            };
                            throw new Error("Failed pattern match at BookIndex (line 122, column 11 - line 128, column 64): " + [ v4.constructor.name ]);
                        })
                    });
                });
            });
        });
    });
})();
var convertProps = function (jsProps) {
    return {
        currentUser: Data_Nullable.toMaybe(jsProps.currentUser),
        books: Data_Maybe.fromMaybe([  ])(Data_Nullable.toMaybe(jsProps.books))
    };
};
var content = function (refHook) {
    if (refHook.isSubmitted === true) {
        return React_Basic.element(MaterialUI.dialogContent)({
            children: [ React_Basic.element(MaterialUI.dialogContentText)({
                children: [ React_Basic_DOM.text("Thank you for uploading! Feel free to start reading and the processing and tagging of your file will be done soon") ]
            }) ],
            style: React_Basic_DOM.css({
                width: "600px"
            })
        });
    };
    if (Data_Boolean.otherwise) {
        return React_Basic.element(MaterialUI.dialogContent)({
            children: [ React_Basic.element(MaterialUI.dialogContentText)({
                children: [ React_Basic_DOM.text("To upload a book choose an .epub file and you're book will be available after a short wait") ]
            }), React_Basic_DOM_Generated.input()({
                type: "file",
                style: React_Basic_DOM.css({
                    display: "none"
                }),
                id: "book-upload",
                accept: ".epub",
                ref: refHook.ref,
                onChange: refHook.onChange
            }), React_Basic_DOM_Generated.label()({
                htmlFor: "book-upload",
                children: [ React_Basic.element(MaterialUI.button)({
                    children: [ React_Basic_DOM.text(refHook.value) ],
                    variant: "contained",
                    component: "span"
                }) ]
            }) ],
            style: React_Basic_DOM.css({
                width: "600px"
            })
        });
    };
    throw new Error("Failed pattern match at BookIndex (line 219, column 1 - line 244, column 8): " + [ refHook.constructor.name ]);
};
var actions = function (setOpen) {
    return function (refHook) {
        return React_Basic.element(MaterialUI.dialogActions)({
            children: [ React_Basic.element(MaterialUI.button)({
                children: [ React_Basic_DOM.text("upload") ],
                color: "primary",
                onClick: refHook.onSubmit
            }) ]
        });
    };
};
var modal = function (dictDiscard) {
    return function (dictDiscard1) {
        return function (isOpen) {
            return function (setOpen) {
                return function (refHook) {
                    return React_Basic.element(MaterialUI.dialog)({
                        children: [ React_Basic.element(MaterialUI.dialogTitle)({
                            children: [ React_Basic_DOM.text("Upload Book") ],
                            style: React_Basic_DOM.css({
                                fontWeight: "600",
                                fontSize: ".875rem"
                            })
                        }), content(refHook), (function () {
                            if (refHook.isSubmitted) {
                                return React_Basic_DOM_Generated.div_([  ]);
                            };
                            return actions(setOpen)(refHook);
                        })() ],
                        onClose: React_Basic_DOM_Events.capture_(Control_Bind.discard(dictDiscard)(Effect.bindEffect)(setOpen(function (v) {
                            return false;
                        }))(function () {
                            return Control_Bind.discard(dictDiscard1)(Effect.bindEffect)(refHook.setIsSubmitted(function (v) {
                                return false;
                            }))(function () {
                                return refHook.setButtonDefault;
                            });
                        })),
                        open: isOpen,
                        ariaLabelledby: "upload-form"
                    });
                };
            };
        };
    };
};
var domQueryState = function (dictDiscard) {
    return function (v) {
        return function (v1) {
            return function (v2) {
                if (v instanceof ApolloHooks["Error"]) {
                    return React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("Error" + v.value0.message) ]);
                };
                if (v instanceof ApolloHooks.Loading) {
                    return React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("Loading") ]);
                };
                if (v instanceof ApolloHooks.Data) {
                    var books = Data_Functor.map(Data_Functor.functorArray)(function (book) {
                        return liElement(book.slug)(book.name);
                    })(v.value0.currentUser.books);
                    return React_Basic_DOM_Generated.div_([ modal(Control_Bind.discardUnit)(dictDiscard)(v1.value0)(v1.value1)(v2), React_Basic.element(MaterialUI.paper)({
                        style: React_Basic_DOM.css({}),
                        children: [ React_Basic_DOM_Generated.div_([ React_Basic_DOM_Generated.div()({
                            style: React_Basic_DOM.css({
                                display: "flex"
                            }),
                            children: [ React_Basic_DOM_Generated.div_([ React_Basic.element(MaterialUI.button)({
                                children: [ React_Basic_DOM.text("Add book") ],
                                onClick: React_Basic_DOM_Events.capture_(v1.value1(function (v3) {
                                    return true;
                                })),
                                variant: "contained",
                                color: "primary",
                                disabled: v.value0.currentUser.isGuest,
                                style: React_Basic_DOM.css({
                                    flex: 1
                                })
                            }) ]), React_Basic_DOM_Generated.div_([ React_Basic_DOM_Generated.a()({
                                children: [ React_Basic_DOM.text("Export Flashcards") ],
                                style: React_Basic_DOM.css({
                                    flex: 1
                                }),
                                href: "/export"
                            }) ]) ]
                        }), React_Basic_DOM_Generated.ul_(books) ]) ]
                    }) ]);
                };
                throw new Error("Failed pattern match at BookIndex (line 170, column 1 - line 171, column 46): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
            };
        };
    };
};
var buildJsx = function (jsProps) {
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(ApolloHooks.useQuery(query)({}))(function (v) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v1) {
            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(inputRef)(function (v2) {
                return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(domQueryState(Control_Bind.discardUnit)(v.state)(v1)(v2));
            });
        });
    });
};
var reactComponent = Effect_Unsafe.unsafePerformEffect(React_Basic_Hooks.component()()("bookIndex")(buildJsx));
module.exports = {
    convertProps: convertProps,
    reactComponent: reactComponent,
    mutation: mutation,
    query: query,
    updateCache: updateCache,
    inputRef: inputRef,
    buildJsx: buildJsx,
    domQueryState: domQueryState,
    liElement: liElement,
    modal: modal,
    content: content,
    actions: actions
};
