// Generated by purs version 0.13.3
"use strict";
var ApolloHooks = require("../ApolloHooks/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Json = require("../Json/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var query = ApolloHooks.gql("\x0a  {\x0a    exportCards {\x0a      text\x0a      translation\x0a      image_url\x0a      start_offset\x0a      word\x0a    }\x0a  }\x0a");
var buildJsx = function (props) {
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(ApolloHooks.useQuery(query)({}))(function (v) {
        if (v.state instanceof ApolloHooks.Data) {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.p_([ React_Basic_DOM.text(Json.stringify(v.state.value0.exportCards)) ]));
        };
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM.text(""));
    });
};
var reactComponent = Effect_Unsafe.unsafePerformEffect(React_Basic_Hooks.component()()("export")(buildJsx));
module.exports = {
    query: query,
    reactComponent: reactComponent,
    buildJsx: buildJsx
};
