const { ApolloClient } = require('apollo-client')
import { setContext } from 'apollo-link-context';
const { InMemoryCache } = require('apollo-cache-inmemory')
import { createLink } from "apollo-absinthe-upload-link";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const storage = global.localStorage || null;
  const token = storage.getItem("treader-session")
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ,
    }
  }
});
export default client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(createLink(
    {
      uri: process.env.GRAPHQL_HOST,
    }
  )),
})

