// Generated by purs version 0.13.3
"use strict";
var $foreign = require("./foreign.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Typelevel_Num_Reps = require("../Data.Typelevel.Num.Reps/index.js");
var Data_Typelevel_Num_Sets = require("../Data.Typelevel.Num.Sets/index.js");
var TupleSize = {};
var ShowNat = {};
var tupleSizeT9 = TupleSize;
var tupleSizeT8 = TupleSize;
var tupleSizeT7 = TupleSize;
var tupleSizeT6 = TupleSize;
var tupleSizeT5 = TupleSize;
var tupleSizeT4 = TupleSize;
var tupleSizeT3 = TupleSize;
var tupleSizeT2 = TupleSize;
var showNatD8 = ShowNat;
var showNatD7 = ShowNat;
var showNatD6 = ShowNat;
var showNatD5 = ShowNat;
var showNatD4 = ShowNat;
var showNatD3 = ShowNat;
var showNatD2 = ShowNat;
var showNatD1 = ShowNat;
var showNatD0 = ShowNat;
var prj = function (dictTupleSize) {
    return function (dictLt) {
        return function (dictShowNat) {
            return function (dictListToRow) {
                return function (dictCons) {
                    return function (dictNat) {
                        return function (n) {
                            return function (t) {
                                return $foreign.prjImpl(Data_Typelevel_Num_Sets.toInt(dictNat)(n))(t);
                            };
                        };
                    };
                };
            };
        };
    };
};
var genericTuple9 = new Data_Generic_Rep.Generic(function (xs) {
    return Data_Generic_Rep.Constructor(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD0)(Data_Typelevel_Num_Reps.d0)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD1)(Data_Typelevel_Num_Reps.d1)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD2)(Data_Typelevel_Num_Reps.d2)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD3)(Data_Typelevel_Num_Reps.d3)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD4)(Data_Typelevel_Num_Reps.d4)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD5)(Data_Typelevel_Num_Reps.d5)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD6)(Data_Typelevel_Num_Reps.d6)(xs))(new Data_Generic_Rep.Product(prj()()()()()(Data_Typelevel_Num_Sets.natD7)(Data_Typelevel_Num_Reps.d7)(xs), prj()()()()()(Data_Typelevel_Num_Sets.natD8)(Data_Typelevel_Num_Reps.d8)(xs))))))))));
}, function (v) {
    return $foreign.t9(v.value0)(v.value1.value0)(v.value1.value1.value0)(v.value1.value1.value1.value0)(v.value1.value1.value1.value1.value0)(v.value1.value1.value1.value1.value1.value0)(v.value1.value1.value1.value1.value1.value1.value0)(v.value1.value1.value1.value1.value1.value1.value1.value0)(v.value1.value1.value1.value1.value1.value1.value1.value1);
});
var genericTuple8 = new Data_Generic_Rep.Generic(function (xs) {
    return Data_Generic_Rep.Constructor(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD0)(Data_Typelevel_Num_Reps.d0)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD1)(Data_Typelevel_Num_Reps.d1)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD2)(Data_Typelevel_Num_Reps.d2)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD3)(Data_Typelevel_Num_Reps.d3)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD4)(Data_Typelevel_Num_Reps.d4)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD5)(Data_Typelevel_Num_Reps.d5)(xs))(new Data_Generic_Rep.Product(prj()()()()()(Data_Typelevel_Num_Sets.natD6)(Data_Typelevel_Num_Reps.d6)(xs), prj()()()()()(Data_Typelevel_Num_Sets.natD7)(Data_Typelevel_Num_Reps.d7)(xs)))))))));
}, function (v) {
    return $foreign.t8(v.value0)(v.value1.value0)(v.value1.value1.value0)(v.value1.value1.value1.value0)(v.value1.value1.value1.value1.value0)(v.value1.value1.value1.value1.value1.value0)(v.value1.value1.value1.value1.value1.value1.value0)(v.value1.value1.value1.value1.value1.value1.value1);
});
var genericTuple7 = new Data_Generic_Rep.Generic(function (xs) {
    return Data_Generic_Rep.Constructor(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD0)(Data_Typelevel_Num_Reps.d0)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD1)(Data_Typelevel_Num_Reps.d1)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD2)(Data_Typelevel_Num_Reps.d2)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD3)(Data_Typelevel_Num_Reps.d3)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD4)(Data_Typelevel_Num_Reps.d4)(xs))(new Data_Generic_Rep.Product(prj()()()()()(Data_Typelevel_Num_Sets.natD5)(Data_Typelevel_Num_Reps.d5)(xs), prj()()()()()(Data_Typelevel_Num_Sets.natD6)(Data_Typelevel_Num_Reps.d6)(xs))))))));
}, function (v) {
    return $foreign.t7(v.value0)(v.value1.value0)(v.value1.value1.value0)(v.value1.value1.value1.value0)(v.value1.value1.value1.value1.value0)(v.value1.value1.value1.value1.value1.value0)(v.value1.value1.value1.value1.value1.value1);
});
var genericTuple6 = new Data_Generic_Rep.Generic(function (xs) {
    return Data_Generic_Rep.Constructor(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD0)(Data_Typelevel_Num_Reps.d0)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD1)(Data_Typelevel_Num_Reps.d1)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD2)(Data_Typelevel_Num_Reps.d2)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD3)(Data_Typelevel_Num_Reps.d3)(xs))(new Data_Generic_Rep.Product(prj()()()()()(Data_Typelevel_Num_Sets.natD4)(Data_Typelevel_Num_Reps.d4)(xs), prj()()()()()(Data_Typelevel_Num_Sets.natD5)(Data_Typelevel_Num_Reps.d5)(xs)))))));
}, function (v) {
    return $foreign.t6(v.value0)(v.value1.value0)(v.value1.value1.value0)(v.value1.value1.value1.value0)(v.value1.value1.value1.value1.value0)(v.value1.value1.value1.value1.value1);
});
var genericTuple5 = new Data_Generic_Rep.Generic(function (xs) {
    return Data_Generic_Rep.Constructor(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD0)(Data_Typelevel_Num_Reps.d0)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD1)(Data_Typelevel_Num_Reps.d1)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD2)(Data_Typelevel_Num_Reps.d2)(xs))(new Data_Generic_Rep.Product(prj()()()()()(Data_Typelevel_Num_Sets.natD3)(Data_Typelevel_Num_Reps.d3)(xs), prj()()()()()(Data_Typelevel_Num_Sets.natD4)(Data_Typelevel_Num_Reps.d4)(xs))))));
}, function (v) {
    return $foreign.t5(v.value0)(v.value1.value0)(v.value1.value1.value0)(v.value1.value1.value1.value0)(v.value1.value1.value1.value1);
});
var genericTuple4 = new Data_Generic_Rep.Generic(function (xs) {
    return Data_Generic_Rep.Constructor(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD0)(Data_Typelevel_Num_Reps.d0)(xs))(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD1)(Data_Typelevel_Num_Reps.d1)(xs))(new Data_Generic_Rep.Product(prj()()()()()(Data_Typelevel_Num_Sets.natD2)(Data_Typelevel_Num_Reps.d2)(xs), prj()()()()()(Data_Typelevel_Num_Sets.natD3)(Data_Typelevel_Num_Reps.d3)(xs)))));
}, function (v) {
    return $foreign.t4(v.value0)(v.value1.value0)(v.value1.value1.value0)(v.value1.value1.value1);
});
var genericTuple3 = new Data_Generic_Rep.Generic(function (xs) {
    return Data_Generic_Rep.Constructor(Data_Generic_Rep.Product.create(prj()()()()()(Data_Typelevel_Num_Sets.natD0)(Data_Typelevel_Num_Reps.d0)(xs))(new Data_Generic_Rep.Product(prj()()()()()(Data_Typelevel_Num_Sets.natD1)(Data_Typelevel_Num_Reps.d1)(xs), prj()()()()()(Data_Typelevel_Num_Sets.natD2)(Data_Typelevel_Num_Reps.d2)(xs))));
}, function (v) {
    return $foreign.t3(v.value0)(v.value1.value0)(v.value1.value1);
});
var genericTuple2 = new Data_Generic_Rep.Generic(function (xs) {
    return new Data_Generic_Rep.Product(prj()()()()()(Data_Typelevel_Num_Sets.natD0)(Data_Typelevel_Num_Reps.d0)(xs), prj()()()()()(Data_Typelevel_Num_Sets.natD1)(Data_Typelevel_Num_Reps.d1)(xs));
}, function (v) {
    return $foreign.t2(v.value0)(v.value1);
});
module.exports = {
    prj: prj,
    TupleSize: TupleSize,
    ShowNat: ShowNat,
    tupleSizeT2: tupleSizeT2,
    tupleSizeT3: tupleSizeT3,
    tupleSizeT4: tupleSizeT4,
    tupleSizeT5: tupleSizeT5,
    tupleSizeT6: tupleSizeT6,
    tupleSizeT7: tupleSizeT7,
    tupleSizeT8: tupleSizeT8,
    tupleSizeT9: tupleSizeT9,
    genericTuple2: genericTuple2,
    genericTuple3: genericTuple3,
    genericTuple4: genericTuple4,
    genericTuple5: genericTuple5,
    genericTuple6: genericTuple6,
    genericTuple7: genericTuple7,
    genericTuple8: genericTuple8,
    genericTuple9: genericTuple9,
    showNatD0: showNatD0,
    showNatD1: showNatD1,
    showNatD2: showNatD2,
    showNatD3: showNatD3,
    showNatD4: showNatD4,
    showNatD5: showNatD5,
    showNatD6: showNatD6,
    showNatD7: showNatD7,
    showNatD8: showNatD8,
    t2: $foreign.t2,
    t3: $foreign.t3,
    t4: $foreign.t4,
    t5: $foreign.t5,
    t6: $foreign.t6,
    t7: $foreign.t7,
    t8: $foreign.t8,
    t9: $foreign.t9
};
