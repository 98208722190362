// Generated by purs version 0.13.3
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Argonaut_Decode_Class = require("../Data.Argonaut.Decode.Class/index.js");
var Data_Argonaut_Encode_Class = require("../Data.Argonaut.Encode.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Char_Unicode = require("../Data.Char.Unicode/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Slug = function (x) {
    return x;
};
var toString = function (v) {
    return v;
};
var semigroupSlug = Data_Semigroup.semigroupString;
var genericSlug = new Data_Generic_Rep.Generic(function (x) {
    return x;
}, function (x) {
    return x;
});
var showSlug = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericSlug)(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showString))(new Data_Symbol.IsSymbol(function () {
    return "Slug";
}))));
var generate = function (s) {
    var words = (function () {
        var $27 = Data_Array.filter(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"]));
        var $28 = Data_String_Common.split(" ");
        return function ($29) {
            return $27($28($29));
        };
    })();
    var stripApostrophes = Data_String_Common.replaceAll("'")("");
    var onlyAlphaNum = (function () {
        var $30 = Data_Functor.map(Data_Functor.functorArray)(function (x) {
            var $17 = Data_Char_Unicode.isAlphaNum(x) && Data_Char_Unicode.isLatin1(x);
            if ($17) {
                return x;
            };
            return " ";
        });
        return function ($31) {
            return Data_String_CodeUnits.fromCharArray($30(Data_String_CodeUnits.toCharArray($31)));
        };
    })();
    var arr = words(Data_String_Common.toLower(onlyAlphaNum(stripApostrophes(s))));
    var $18 = Data_Array["null"](arr);
    if ($18) {
        return Data_Maybe.Nothing.value;
    };
    return Data_Maybe.Just.create(Slug(Data_String_Common.joinWith("-")(arr)));
};
var parse = function (str) {
    var check = function (v) {
        if (v === str) {
            return new Data_Maybe.Just(v);
        };
        if (Data_Boolean.otherwise) {
            return Data_Maybe.Nothing.value;
        };
        throw new Error("Failed pattern match at Slug (line 96, column 5 - line 98, column 28): " + [ v.constructor.name ]);
    };
    return Control_Bind.bind(Data_Maybe.bindMaybe)(generate(str))(check);
};
var truncate = function (n) {
    return function (v) {
        if (n < 1) {
            return Data_Maybe.Nothing.value;
        };
        if (n >= Data_String_CodePoints.length(v)) {
            return new Data_Maybe.Just(v);
        };
        if (Data_Boolean.otherwise) {
            return generate(Data_String_CodePoints.take(n)(v));
        };
        throw new Error("Failed pattern match at Slug (line 118, column 1 - line 118, column 38): " + [ n.constructor.name, v.constructor.name ]);
    };
};
var eqSlug = new Data_Eq.Eq(function (x) {
    return function (y) {
        return x === y;
    };
});
var ordSlug = new Data_Ord.Ord(function () {
    return eqSlug;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(Data_Ord.ordString)(x)(y);
    };
});
var encodeJsonSlug = new Data_Argonaut_Encode_Class.EncodeJson(function (v) {
    return Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeJsonJString)(v);
});
var decodeJsonSlug = new Data_Argonaut_Decode_Class.DecodeJson(Control_Bind.composeKleisliFlipped(Data_Either.bindEither)((function () {
    var $32 = Data_Either.note("Failed to decode slug");
    return function ($33) {
        return $32(parse($33));
    };
})())(Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeJsonString)));
module.exports = {
    generate: generate,
    parse: parse,
    toString: toString,
    truncate: truncate,
    genericSlug: genericSlug,
    eqSlug: eqSlug,
    ordSlug: ordSlug,
    semigroupSlug: semigroupSlug,
    showSlug: showSlug,
    encodeJsonSlug: encodeJsonSlug,
    decodeJsonSlug: decodeJsonSlug
};
