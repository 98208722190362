import React from "react";
import { CheckLabel } from "./styled";
import { jsWordTranslation as WordTranslation } from "~/output/WordTranslation.Interop";
import { colors } from "../constants"
import Table from 'react-bootstrap/Table';
import { useQuery, useMutation } from "react-apollo";
import { gql } from 'apollo-boost';
import client from "../apolloClient"

let borderStyle = {
  border: '1px solid black',
}

let tableStyle = {
  borderCollapse: "collapse",
}

let valueMap = {
  'ADP': 'adposition',
  'VERB': 'verb',
  'NOUN': 'noun',
  'PROPN': 'proper noun',
  'ADJ': 'adjective',
  'CONJ': 'conjunction',
  'ADV': 'adverb',
  'DET': 'determiner',
  'Pres': 'present',
  'Sing': 'singular',
  'Plur': 'plural',
  'Fin': 'finite',
  'Masc': 'masculine',
  'Fem': 'feminine',
  'Inf': 'infinitive',
  'AUX': 'auxiliary',
  'Ger': 'gerund',
  'Imp': 'imperfect',
  'SCONJ': 'subordinating conjunctions',
  'PRON': 'pronoun',
}

const mutation = gql`
mutation commentMutation($input: CommentInput!) {
  comment(input: $input) {
    result
  }
}
`;

function BottomContent(props) {

  const [mutate, { mutationData }]  = useMutation(mutation, {client: client})
  let saveComment = function(e) {
    e.preventDefault();
    mutate({variables: {input: {cfirange: props.epubcfi, text: this.refs.commentItem.value, book: "Harry Potter"}}})
  }

  let mapValue = function(label, value) {
    if(label != "infinitive") {
      return valueMap[value] || value;
    } else {
      return value;
    }
  }

  let createRow = function(label, value) {
    return(
      <tr key={label}>
        <td style={borderStyle}>{label}</td>
        <td style={borderStyle}>{mapValue(label, value)}</td>
      </tr>
    )
  }

  let addRow = function(rows, label, key) {
    let value = props.wordData[key];
    if (value) {
      rows.push(createRow(label, value));
    }
    return rows;
  }

  let createTable = function() {
    let rows = []
    if (props.wordData["pos"] == "VERB" || props.wordData["pos"] == "AUX") {
      rows = addRow(rows, "infinitive", "lemma");
    }
    rows = addRow(rows, "Part Of Speech", "pos");
    rows = addRow(rows, "Gender", "gender");
    rows = addRow(rows, "Verb Tense", "tense");
    rows = addRow(rows, "Verb Form", "verbform");
    rows = addRow(rows, "Number", "number");
    rows = addRow(rows, "Person", "person");
    return rows
  }

  if(props.commentMode) {
    return(
      <div style={{flex: 11, paddingLeft: "10px"}}> <form onSubmit={saveComment}>
          <textarea ref="commentItem" style={{width: "100%"}}/>
          <input type="submit"/>
        </form>
      </div>
    );
  }
  else if (props.mode === 'viewComment' || props.mode === 'clickKnown') {
    return(
      <div style={{flex: 11, paddingLeft: "10px"}}>
        {props.commentText}
      </div>
    );
  } else if (props.mode === 'read' && props.wordData) {
    return(
      <div style={{flex: 11, paddingLeft: "10px", paddingRight: "10px", display: "flex", overflow: "scroll" }}>
        <div style={{flex: 1, marginLeft: "5px"}}>
          <WordTranslation highlightedContent={props.highlightedContent}/>
        </div>
        <div style={{flex: 1, borderLeft: '1px solid black'}}>
          <Table style={tableStyle} size="sm" bordered hover>
            <tbody>
              {createTable()}
            </tbody>
          </Table>
        </div>
      </div>
    );
  } else if (props.mode === 'read') {
    return(
      <div style={{flex: 11, paddingLeft: "10px", display: "flex", overflow: "scroll" }}>
        <div style={{flex: 1, marginLeft: "5px", paddingRight: "10px"}}>
          <WordTranslation highlightedContent={props.highlightedContent}/>
        </div>

        <div style={{flex: 1, paddingLeft: "10px", borderLeft: '1px solid black'}}>
          <input checked={props.highlightVerbs} onChange={props.toggleVerb} type="checkbox"/><CheckLabel style={{color: colors["green"]}}>Verb</CheckLabel><br/>
          <input checked={props.highlightNouns} onChange={props.toggleNoun} type="checkbox"/><CheckLabel style={{color: colors["orange"]}}>Noun</CheckLabel><br/>
          <input checked={props.highlightAdjectives} onChange={props.toggleAdjective} type="checkbox"/><CheckLabel style={{color: colors["red"]}}>Adjective</CheckLabel>
        </div>
      </div>
    );
  }
}

export default BottomContent;
