// Generated by purs version 0.13.3
"use strict";
var ApolloHooks = require("../ApolloHooks/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Promise = require("../Control.Promise/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Field = require("../Field/index.js");
var FormCard = require("../FormCard/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Stripe = require("../Stripe/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var Web_Storage_Storage = require("../Web.Storage.Storage/index.js");
var mutation = ApolloHooks.gql("\x0amutation userMutation($input: UserInput!) {\x0a  user(input: $input) {\x0a    session {token}\x0a  }\x0a}\x0a");
var isEmpty = React_Basic_Events.unsafeEventFn(function (e) {
    return e.empty;
});
var headerStyle = React_Basic_DOM.css({
    textAlign: "center"
});
var formStyle = React_Basic_DOM.css({});
var convertProps = function (jsProps) {
    var token = Data_Maybe.fromMaybe(function (x) {
        return Control_Promise.fromAff(Data_Monoid.mempty(Effect_Aff.monoidAff(Data_Monoid.monoidRecord()(Data_Monoid.monoidRecordCons(new Data_Symbol.IsSymbol(function () {
            return "token";
        }))(Data_Monoid.monoidRecord()(Data_Monoid.monoidRecordCons(new Data_Symbol.IsSymbol(function () {
            return "id";
        }))(Data_Monoid.monoidString)()(Data_Monoid.monoidRecordNil)))()(Data_Monoid.monoidRecordNil)))));
    })(Data_Functor.map(Data_Maybe.functorMaybe)(Effect_Uncurried.runEffectFn1)(Data_Nullable.toMaybe(jsProps.stripe.createToken)));
    var mapAff = function (f) {
        return function (x) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(f(x)))(Control_Promise.toAff);
        };
    };
    return {
        stripe: {
            createToken: mapAff(token)
        },
        push: Effect_Uncurried.runEffectFn1(jsProps.router.push)
    };
};
var cardChangeHandler = function (replaceState) {
    return React_Basic_Events.handler(React_Basic_Events.merge()(React_Basic_Events.mergeCons(new Data_Symbol.IsSymbol(function () {
        return "isEmpty";
    }))()()()()(React_Basic_Events.mergeNil))({
        isEmpty: isEmpty
    }))(function (v) {
        return replaceState(function (v1) {
            return {
                empty: v.isEmpty,
                focused: v1.focused,
                value: v1.value
            };
        });
    });
};
var buildJsx = function (props) {
    var fieldsStyle = React_Basic_DOM.css({
        maxWidth: "500px",
        width: "100%",
        margin: "0 auto"
    });
    var convertedProps = convertProps(props);
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(ApolloHooks.useMutation(mutation)({}))(function (v) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(Field.useInput("")(Field.changeHandler))(function (v1) {
            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(Field.useInput("")(Field.changeHandler))(function (v2) {
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(Field.useInput("")(Field.changeHandler))(function (v3) {
                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(Field.useInput("")(Field.changeHandler))(function (v4) {
                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(Field.useInput("")(Field.changeHandler))(function (v5) {
                            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(Field.useInput("")(Field.changeHandler))(function (v6) {
                                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(Field.useInput("")(cardChangeHandler))(function (v7) {
                                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(Field.useInput("")(cardChangeHandler))(function (v8) {
                                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v9) {
                                            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(Field.useInput("")(cardChangeHandler))(function (v10) {
                                                var userSection = React_Basic_DOM_Generated.fieldset()({
                                                    children: [ React_Basic_DOM_Generated.h1()({
                                                        children: [ React_Basic_DOM.text("Subscribe") ],
                                                        style: headerStyle
                                                    }), Field.renderRow([ {
                                                        inputs: v3,
                                                        label: "Email"
                                                    } ]), Field.renderRow([ {
                                                        inputs: v1,
                                                        label: "First Name"
                                                    }, {
                                                        inputs: v2,
                                                        label: "Last Name"
                                                    } ]), Field.renderRow([ {
                                                        inputs: v4,
                                                        label: "Password"
                                                    } ]), Field.renderRow([ {
                                                        inputs: v5,
                                                        label: "Password Confirmation"
                                                    } ]) ],
                                                    style: fieldsStyle
                                                });
                                                var cardSection = React_Basic_DOM_Generated.fieldset()({
                                                    children: [ React_Basic_DOM_Generated.h2()({
                                                        children: [ React_Basic_DOM.text("Payment Information") ],
                                                        style: headerStyle
                                                    }), Field.renderRow([ {
                                                        inputs: v6,
                                                        label: "Name on Card"
                                                    } ]), Field.renderCardRow([ {
                                                        inputs: v7,
                                                        label: "Card Number"
                                                    } ])(React_Basic.element(Stripe.cardNumberElement)), Field.renderCardRow([ {
                                                        inputs: v8,
                                                        label: "Expiration Date"
                                                    } ])(React_Basic.element(Stripe.cardExpiryElement)), Field.renderCardRow([ {
                                                        inputs: v10,
                                                        label: "CVC"
                                                    } ])(React_Basic.element(Stripe.cardCvcElement)), React_Basic_DOM_Generated.input()({
                                                        type: "submit",
                                                        value: "Subscribe for $10 a month",
                                                        style: React_Basic_DOM.css({
                                                            width: "calc(100% - 30px)",
                                                            backgroundColor: "#24b47e",
                                                            color: "white",
                                                            textTransform: "uppercase",
                                                            margin: "40px 15px 0",
                                                            height: "40px",
                                                            fontWeight: 600,
                                                            borderRadius: "4px"
                                                        }),
                                                        disabled: v9.value0
                                                    }) ],
                                                    style: fieldsStyle
                                                });
                                                return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.form()({
                                                    children: [ React_Basic.element(FormCard.reactComponent)({
                                                        children: [ userSection ]
                                                    }), React_Basic.element(FormCard.reactComponent)({
                                                        children: [ cardSection ]
                                                    }) ],
                                                    style: formStyle,
                                                    onSubmit: React_Basic_Events.handler(React_Basic_DOM_Events.preventDefault)(function (v11) {
                                                        return function __do() {
                                                            var v12 = Web_HTML.window();
                                                            var v13 = Web_HTML_Window.localStorage(v12)();
                                                            v9.value1(function (v14) {
                                                                return true;
                                                            })();
                                                            return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(convertedProps.stripe.createToken({
                                                                name: v6.value
                                                            }))(function (v14) {
                                                                return Control_Bind.bind(Effect_Aff.bindAff)(v.value0({
                                                                    variables: {
                                                                        input: {
                                                                            firstName: v1.value,
                                                                            lastName: v2.value,
                                                                            email: v3.value,
                                                                            password: v4.value,
                                                                            tokenId: v14.token.id
                                                                        }
                                                                    }
                                                                }))(function (v15) {
                                                                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Web_Storage_Storage.setItem("treader-session")("Bearer " + v15.data.user.session.token)(v13)))(function () {
                                                                        return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(convertedProps.push("/books"));
                                                                    });
                                                                });
                                                            }))();
                                                        };
                                                    })
                                                }));
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    });
};
var reactComponent = Effect_Unsafe.unsafePerformEffect(React_Basic_Hooks.component()()("Signup")(buildJsx));
module.exports = {
    mutation: mutation,
    reactComponent: reactComponent,
    convertProps: convertProps,
    isEmpty: isEmpty,
    cardChangeHandler: cardChangeHandler,
    headerStyle: headerStyle,
    formStyle: formStyle,
    buildJsx: buildJsx
};
