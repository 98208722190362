// Generated by purs version 0.13.3
"use strict";
var $foreign = require("./foreign.js");
module.exports = {
    dialog: $foreign.dialog,
    dialogActions: $foreign.dialogActions,
    dialogContent: $foreign.dialogContent,
    dialogContentText: $foreign.dialogContentText,
    dialogTitle: $foreign.dialogTitle,
    button: $foreign.button,
    paper: $foreign.paper,
    addIcon: $foreign.addIcon,
    textField: $foreign.textField,
    "_makeStyles": $foreign["_makeStyles"]
};
