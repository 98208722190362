// Generated by purs version 0.13.3
"use strict";
var Data_Nullable = require("../Data.Nullable/index.js");
var React_Basic = require("../React.Basic/index.js");
var WordTranslation = require("../WordTranslation/index.js");
var jsPropsToProps = function (props) {
    return {
        highlightedContent: Data_Nullable.toMaybe(props.highlightedContent)
    };
};
var jsWordTranslation = React_Basic.toReactComponent()(jsPropsToProps)(WordTranslation.component)({
    initialState: WordTranslation.initialState,
    render: WordTranslation.render
});
module.exports = {
    jsPropsToProps: jsPropsToProps,
    jsWordTranslation: jsWordTranslation
};
