import React from "react";
import Header from "./header";
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

const GET_USER = gql`
query getUser {
  currentUser {
    id
    firstName
    lastName
    email
  }
}`;

function Layout({router, children, match}) {
  const { loading, error, data, refetch } = useQuery(GET_USER);
  let headerStyles = {
    paddingLeft: "10px",
    borderBottom: "3px solid black",
    backgroundColor: "#20232a",
    height: "50px",
    display: "flex",
    paddingTop: 5,
    color: "white",
    justifyContent: "center",
    alignContent: "center"
  }
  if (loading) return <p>Loading ...</p>;
  if (error) return <p>{error}</p>;
  let lastRoute = match.routes.pop();
  if (lastRoute.path == "/books/:book") {
    return children;
  } else {
    return(
      <div>
        <div style={headerStyles}>
          <Header router={router} user={data.currentUser} refetchUser={refetch}/>
        </div>
        {children}
      </div>
    )
  }
}

export default Layout;
