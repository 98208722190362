// Generated by purs version 0.13.3
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Record = require("../Record/index.js");
var Slug = require("../Slug/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var useInput = function (initialValue) {
    return function (changeHandler1) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState({
            value: initialValue,
            focused: false,
            empty: true
        }))(function (v) {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))({
                onChange: changeHandler1(v.value1),
                value: v.value0.value,
                empty: v.value0.empty,
                focused: v.value0.focused,
                onFocus: React_Basic_Events.handler(React_Basic_Events.merge()(React_Basic_Events.mergeCons(new Data_Symbol.IsSymbol(function () {
                    return "timeStamp";
                }))()()()()(React_Basic_Events.mergeNil))({
                    timeStamp: React_Basic_DOM_Events.timeStamp
                }))(function (v1) {
                    return v.value1(function (v2) {
                        return {
                            value: v.value0.value,
                            empty: v.value0.empty,
                            focused: true
                        };
                    });
                }),
                onBlur: React_Basic_Events.handler(React_Basic_Events.merge()(React_Basic_Events.mergeCons(new Data_Symbol.IsSymbol(function () {
                    return "timeStamp";
                }))()()()()(React_Basic_Events.mergeNil))({
                    timeStamp: React_Basic_DOM_Events.timeStamp
                }))(function (v1) {
                    return v.value1(function (v2) {
                        return {
                            value: v.value0.value,
                            empty: v.value0.empty,
                            focused: false
                        };
                    });
                })
            });
        });
    };
};
var slug = function (label) {
    return Slug.generate(label);
};
var rowStyle = React_Basic_DOM.css({
    display: "flex",
    alignItems: "center",
    marginLeft: "15px",
    padding: "11px 15px 11px 0"
});
var renderRowElements = function (children) {
    return React_Basic_DOM_Generated.div()({
        style: rowStyle,
        children: children
    });
};
var inputType = function (slug1) {
    var $22 = Data_String_CodeUnits.contains("password")(slug1);
    if ($22) {
        return "password";
    };
    return "text";
};
var inputStyle = React_Basic_DOM.css({
    flex: 1,
    borderStyle: "none",
    width: "100%",
    paddingBottom: "7px",
    outline: "none"
});
var formatSlug = function (v) {
    if (v instanceof Data_Maybe.Just) {
        return Slug.toString(v.value0);
    };
    if (v instanceof Data_Maybe.Nothing) {
        return "";
    };
    throw new Error("Failed pattern match at Field (line 170, column 1 - line 170, column 44): " + [ v.constructor.name ]);
};
var getSlug = function ($43) {
    return formatSlug(slug($43));
};
var focusedInputStype = React_Basic_DOM.css({
    color: "#24b47e"
});
var fieldStyle = React_Basic_DOM.css({
    width: "100%",
    position: "relative"
});
var defaultLabelStyle = {
    position: "absolute",
    width: "100%",
    left: "0",
    bottom: "8px",
    color: "#cfd7df",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    transformOrigin: "0 50%",
    cursor: "text",
    transitionProperty: "color, transform",
    transitionDuration: "0.3s",
    transitionTimingFunction: "cubic-bezier(0.165, 0.84, 0.44, 1)",
    marginBottom: 0
};
var emptyLabelStyle = React_Basic_DOM.css(Record.merge()()(defaultLabelStyle)({}));
var nonEmptyLabelStyle = function (focused) {
    return React_Basic_DOM.css(Record.merge()()({
        opacity: 1,
        color: (function () {
            if (focused) {
                return "#24b47e";
            };
            return "#aab7c4";
        })(),
        transform: "scale(0.85) translateY(-25px)",
        cursor: "default"
    })(defaultLabelStyle));
};
var labelStyle = function (v) {
    return function (v1) {
        if (v1) {
            return nonEmptyLabelStyle(true);
        };
        if (v && !v1) {
            return emptyLabelStyle;
        };
        if (!v1) {
            return nonEmptyLabelStyle(false);
        };
        throw new Error("Failed pattern match at Field (line 174, column 1 - line 174, column 48): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var changeHandler = function (replaceState) {
    var getValue = function (a) {
        return Data_Maybe.fromMaybe("")(a);
    };
    return React_Basic_Events.handler(Control_Semigroupoid.composeFlipped(React_Basic_Events.semigroupoidBuilder)(React_Basic_DOM_Events.preventDefault)(Control_Semigroupoid.composeFlipped(React_Basic_Events.semigroupoidBuilder)(React_Basic_DOM_Events.stopPropagation)(React_Basic_Events.merge()(React_Basic_Events.mergeCons(new Data_Symbol.IsSymbol(function () {
        return "targetValue";
    }))()()()()(React_Basic_Events.mergeCons(new Data_Symbol.IsSymbol(function () {
        return "timeStamp";
    }))()()()()(React_Basic_Events.mergeNil)))({
        targetValue: React_Basic_DOM_Events.targetValue,
        timeStamp: React_Basic_DOM_Events.timeStamp
    }))))(function (v) {
        return replaceState(function (v1) {
            return {
                value: getValue(v.targetValue),
                empty: getValue(v.targetValue) === "",
                focused: v1.focused
            };
        });
    });
};
var baselineStyle = function (focused) {
    return React_Basic_DOM.css({
        position: "absolute",
        width: "100%",
        marginBottom: "2px",
        height: "1px",
        left: "0",
        bottom: "0",
        backgroundColor: (function () {
            if (focused) {
                return "#24b47e";
            };
            return "#cfd7df";
        })(),
        transition: "background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1)"
    });
};
var renderInputWith = function (v) {
    return function (input) {
        return React_Basic_DOM_Generated.div()({
            children: [ React_Basic_DOM_Generated.label()({
                children: [ React_Basic_DOM.text(v.label) ],
                style: labelStyle(v.inputs.empty)(v.inputs.focused),
                htmlFor: getSlug(v.label)
            }), input({
                onChange: v.inputs.onChange,
                onFocus: v.inputs.onFocus,
                onBlur: v.inputs.onBlur,
                value: v.inputs.value,
                style: inputStyle,
                id: getSlug(v.label),
                type: inputType(getSlug(v.label)),
                placeholder: ""
            }), React_Basic_DOM_Generated.div()({
                children: [  ],
                style: baselineStyle(v.inputs.focused)
            }) ],
            style: fieldStyle
        });
    };
};
var renderCardRow = function (inputInformation) {
    return function (inputElement) {
        return renderRowElements(Data_Functor.map(Data_Functor.functorArray)(Data_Function.flip(renderInputWith)(inputElement))(inputInformation));
    };
};
var renderInput = function (v) {
    return renderInputWith({
        inputs: v.inputs,
        label: v.label
    })(React_Basic_DOM_Generated.input());
};
var renderRow = function (inputInformation) {
    return renderRowElements(Data_Functor.map(Data_Functor.functorArray)(renderInput)(inputInformation));
};
module.exports = {
    renderRowElements: renderRowElements,
    defaultLabelStyle: defaultLabelStyle,
    focusedInputStype: focusedInputStype,
    rowStyle: rowStyle,
    inputStyle: inputStyle,
    fieldStyle: fieldStyle,
    emptyLabelStyle: emptyLabelStyle,
    nonEmptyLabelStyle: nonEmptyLabelStyle,
    changeHandler: changeHandler,
    useInput: useInput,
    renderRow: renderRow,
    renderCardRow: renderCardRow,
    renderInputWith: renderInputWith,
    renderInput: renderInput,
    inputType: inputType,
    slug: slug,
    getSlug: getSlug,
    formatSlug: formatSlug,
    labelStyle: labelStyle,
    baselineStyle: baselineStyle
};
